import React from "react";
import BigCalendar from "src/page-components/account/calendar/BigCalendar";
import ScheduleManager from "src/page-components/account/calendar/ScheduleManager";

const CleanerCalendar = () => {
  return (
    <div className="mt-10 items-stretch gap-10 pb-[100px] sm:flex">
      <div className="w-full border-[#DADCE0] pr-2 sm:w-1/4 sm:border-r">
        <ScheduleManager />
      </div>
      <div className="mt-3 w-full border-t border-[#DADCE0] pt-6 sm:w-3/4 sm:border-none">
        <BigCalendar />
      </div>
    </div>
  );
};

export default CleanerCalendar;
