import React, { FC, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import bookingServices from "src/services/booking-service";
import useBookCleaningStore from "src/store/book-cleaning-store";
import { Button } from "src/components/Button";
import { SortingDropdownList } from "src/components/SortDropdownList";
import { IOptionSelect } from "src/@types/form";
import { StepControlsProps } from "../types";
import { Title } from "./Title";

const TEST_OPTIONS: IOptionSelect[] = [
  { label: "Basic Cleaning", value: "basic-cleaning" },
  { label: "Deep Cleaning", value: "deep-cleaning" },
  { label: "Move Out Cleaning", value: "move-out-cleaning" },
  { label: "Post Construction", value: "post-construction" },
];

export const NUMBER_OF_CLEANERS_OPTIONS: IOptionSelect[] = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
];

export const TypeOfCleaning: FC<StepControlsProps> = ({ title, subtitle }) => {
  const { data: cleaningTypes, isLoading } = useQuery({
    queryKey: ["cleaningTypes"],
    queryFn: bookingServices.getCleaningTypes,
  });

  const { team_size, cleaning_type, updateField } = useBookCleaningStore();

  const handleTeamSize = (option: { label: string; value: number }) => {
    updateField("team_size", option);
  };

  const handleCleaningType = (option: {
    id: number;
    name: string;
    description: string;
  }) => {
    updateField("cleaning_type", option);
  };

  return (
    <div className="flex flex-col gap-10">
      <Title title={title} subtitle={subtitle} />
      <div className="flex flex-col gap-6">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <SortingDropdownList
            headLabel="Type of Cleaning"
            placeholder="Select the type of cleaning"
            options={cleaningTypes}
            activeOption={cleaning_type}
            setOption={handleCleaningType}
            labelKey="name"
            valueKey="id"
          />
        )}

        <SortingDropdownList
          headLabel="Number of Cleaners"
          placeholder="Select the number of cleaners"
          options={NUMBER_OF_CLEANERS_OPTIONS}
          activeOption={team_size}
          setOption={handleTeamSize}
        />
      </div>
    </div>
  );
};
