import React, { FC } from "react";
import iconImg from "src/assets/images/home/how-it-works/icon.png";
import { HOW_IT_WORKS_ITEMS } from "./constants";

export const HowItWorks: FC = () => {
  return (
    <section className="bg-blue-ligther">
      <div className="container flex flex-col items-center gap-24 py-16 lg:flex-row">
        {HOW_IT_WORKS_ITEMS.map(({ id, title, subtitle }) => (
          <div
            key={id}
            className="flex flex-1 flex-col items-center gap-4 lg:items-start"
          >
            <div className="h-16 w-20">
              <img src={iconImg} alt={title} />
            </div>

            <span className="text-2xl font-semibold text-black-ligth">
              {title}
            </span>

            <span className="text-black-medium">{subtitle}</span>
          </div>
        ))}
      </div>
    </section>
  );
};
