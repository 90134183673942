export enum Users {
  CLIENT = "client",
  CLEANER = "cleaner",
}

export interface ITeamMember {
  name: string;
  experience_years: number;
}

export interface ICleaningType {
  id: number;
  name: string;
  label: string;
}

export interface ICommonUser {
  id: number;

  name: string;
  phone_number: string;
  email: string;
  avatar: string;

  state?: string;
  address?: string;
  zip_code?: string;
  experience_years?: number;
  bio?: string;
  cleaning_types?: ICleaningType[];
  team_members?: ITeamMember[];
  license?: File | null;
  insurance?: File | null;
  profile_status: string;
}

export interface IClient extends ICommonUser {
  state?: string;
  address?: string;
}

export interface ICleaner extends ICommonUser {
  zip_code?: string;
  experience_years?: number;
  bio?: string;
  cleaning_types?: ICleaningType[];
  team_members?: [];
}

export enum IProfileStatus {
  UNCOMPLETED_PROFILE = "UNCOMPLETED_PROFILE",
  UNSIGNED_AGREEMENT = "UNSIGNED_AGREEMENT",
  ON_MODERATION = "ON_MODERTAION",
  FAILED_MODERATION = "FAILED_MODERATION",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  PUBLISHED = "PUBLISHED",
}

export enum States {
  "AL" = "AL",
  "AK" = "AK",
  "AS" = "AS",
  "AZ" = "AZ",
  "AR" = "AR",
  "CA" = "CA",
  "CO" = "CO",
  "CT" = "CT",
  "DE" = "DE",
  "DC" = "DC",
  "FM" = "FM",
  "FL" = "FL",
  "GA" = "GA",
  "GU" = "GU",
  "HI" = "HI",
  "ID" = "ID",
  "IL" = "IL",
  "IN" = "IN",
  "IA" = "IA",
  "KS" = "KS",
  "KY" = "KY",
  "LA" = "LA",
  "ME" = "ME",
  "MH" = "MH",
  "MD" = "MD",
  "MA" = "MA",
  "MI" = "MI",
  "MN" = "MN",
  "MS" = "MS",
  "MO" = "MO",
  "MT" = "MT",
  "NE" = "NE",
  "NV" = "NV",
  "NH" = "NH",
  "NJ" = "NJ",
  "NM" = "NM",
  "NY" = "NY",
  "NC" = "NC",
  "ND" = "ND",
  "MP" = "MP",
  "OH" = "OH",
  "OK" = "OK",
  "OR" = "OR",
  "PW" = "PW",
  "PA" = "PA",
  "PR" = "PR",
  "RI" = "RI",
  "SC" = "SC",
  "SD" = "SD",
  "TN" = "TN",
  "TX" = "TX",
  "UT" = "UT",
  "VT" = "VT",
  "VI" = "VI",
  "VA" = "VA",
  "WA" = "WA",
  "WV" = "WV",
  "WI" = "WI",
  "WY" = "WY",
}
