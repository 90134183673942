import { instance } from "src/services/api-client";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ISubscriptionStore {
  isLoading: boolean;
  userSubscription: any;
  activeUserSubscription: any;
  fetchUserSubscription: () => void;
  cancelSubscription: (id: number) => void;
}

const useSubscriptionStore = create(
  devtools<ISubscriptionStore>((set) => ({
    isLoading: false,
    userSubscription: [],
    activeUserSubscription: null,
    fetchUserSubscription: async () => {
      set({ isLoading: true });
      try {
        debugger;
        const { data } = await instance.get("/api/subscriptions");
        set({ userSubscription: data });
        const activeSubscriptions = Array.isArray(data)
          ? data.find((sub) => sub.status === "active")
          : null;
        set({ activeUserSubscription: activeSubscriptions });
      } catch (error) {
        console.log(error);
      } finally {
        set({ isLoading: false });
      }
    },
    cancelSubscription: async (id) => {
      set({ isLoading: true });
      try {
        await instance.post(`api/subscriptions/${id}/cancel`);

        set({ userSubscription: null });
        set({ activeUserSubscription: null });
        setTimeout(async () => {
          await useSubscriptionStore.getState().fetchUserSubscription();
        }, 20000); // 10 секунд
      } catch (error) {
        console.log(error);
      } finally {
        set({ isLoading: false });
      }
    },
  }))
);

export default useSubscriptionStore;
