import { PATHNAMES } from "src/constants/routes";

export const ACCOUNT_TABS_ITEMS = [
  {
    id: "personal-data",
    label: "Personal data",
    href: PATHNAMES.ACCOUNT_PERSONAl_DATA,
    mobileHref: PATHNAMES.ACCOUNT_PERSONAl_DATA_MOBILE,
  },
  {
    id: "cleaner-calendar",
    label: "Calendar",
    href: PATHNAMES.ACCOUNT_CALENDAR,
    mobileHref: PATHNAMES.ACCOUNT_PERSONAl_DATA_MOBILE,
  },
  {
    id: "bookings",
    label: "Bookings",
    href: PATHNAMES.ACCOUNT_BOOKINGS,
    mobileHref: PATHNAMES.ACCOUNT_BOOKINGS_MOBILE,
  },
  {
    id: "review",
    label: "Review",
    href: PATHNAMES.ACCOUNT_REVIEW,
    mobileHref: PATHNAMES.ACCOUNT_REVIEW_MOBILE,
  },
];
