import { FC } from "react";
import { Link } from "react-router-dom";
import { TextInput } from "src/ui/TextInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { PATHNAMES } from "src/constants/routes";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  newEmailAddress: string;
  setNewEmailAddress: (value: string) => void;
  setIsConfirmationModalOpen: (value: boolean) => void;
}

export const ChangeEmail: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  newEmailAddress,
  setNewEmailAddress,
  setIsConfirmationModalOpen,
}) => {
  const handleSendClick = () => {
    setIsModalOpen(false);
    setIsConfirmationModalOpen(true);
  };

  return (
    <ModalWindow
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isShownCloseButton={false}
      isActiveCloseClickOutside={true}
      className="flex justify-center"
    >
      <ModalPrimaryTemplate
        className="w-[375px] lg:w-[700px]"
        onClose={() => setIsModalOpen(false)}
      >
        <h3 className="text-center text-[28px] lg:text-left">
          Change Your Email Address
        </h3>
        <p className="mt-5 text-center text-[16px]">
          We’ve noticed you’d like to change your email address. Please enter
          your new email below, and we’ll send a confirmation to update your
          account.
        </p>
        <div className="mt-5">
          <TextInput
            name="email"
            label="New Email Address"
            onChange={(e) => setNewEmailAddress(e.target.value)}
            value={newEmailAddress}
          />
        </div>

        <Button
          variant={ButtonVariants.PRIMARY}
          className="mt-5 w-full px-5 py-2"
          onClick={handleSendClick}
        >
          Update email
        </Button>

        <hr className="my-5 h-[1px] w-full bg-[#393D50]" />

        <p>Didn't get the email?</p>

        <dl className="mt-5">
          <li>Check your spam or junk folder.</li>
          <li>
            Still no luck? Contact our
            <Link className="ml-1 text-yellow-base" to={PATHNAMES.HOME}>
              support team&nbsp;
            </Link>
            for assistance.
          </li>
        </dl>
      </ModalPrimaryTemplate>
    </ModalWindow>
  );
};
