import { FormFieldVariants } from "./types";

export const FORM_FIELD_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]: "",
};

export const TEXT_INPUT_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]:
    "bg-white-base w-full rounded-full border border-gray-base text-sm placeholder:text-gray-base py-3 px-4",
};

export const LABEL_STYLE_VARIANTS = {
  [FormFieldVariants.PRIMARY]: "",
};
