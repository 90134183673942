import React, { FC, useEffect, useState } from "react";
import useSubscriptionStore from "src/store/subscription-store";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { SubscriptionSection } from "src/components/Subscription";

const Subscription: FC = () => {
  const [message, setMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const isLoading = useSubscriptionStore((state) => state.isLoading);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get("success");

    if (success === "true") {
      setMessage("Subscription successfully completed!");
      setIsModalOpen(true); // Открываем модальное окно
    } else if (success === "false") {
      setMessage("Subscription failed. Please try again.");
      setIsModalOpen(true); // Открываем модальное окно
    }
  }, []);

  return (
    <PageWrapper>
      {isLoading && <div>Loading...</div>}
      <SubscriptionSection />

      <ModalWindow
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isShownCloseButton={false}
        className="w-[600px]"
      >
        <ModalPrimaryTemplate onClose={() => setIsModalOpen(false)}>
          <div>{message}</div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </PageWrapper>
  );
};

export default Subscription;
