import { FC } from "react";
import { useNavigate } from "react-router";
import { ReactComponent as LaptopIcon } from "src/assets/icons/laptop-icon.svg";
import { ReactComponent as MailIcon } from "src/assets/icons/mail.svg";
import { ReactComponent as PhoneIcon } from "src/assets/icons/phone.svg";
import { ReactComponent as WarningIcon } from "src/assets/icons/warning-icon.svg";

interface Props {
  handleCompleteProfile: () => void;
}

export const CompleteProfile: FC<Props> = ({ handleCompleteProfile }) => {
  const navigate = useNavigate();

  const handleComplete = () => {
    handleCompleteProfile();
    navigate("/account");
  };

  return (
    <div className="bg-white flex w-full flex-col items-center rounded-lg p-6 shadow-lg">
      {/* Warning Icon */}
      <div className="mb-4">
        <WarningIcon className="h-10 w-10 text-yellow-500" />
      </div>

      {/* Title */}
      <h2 className="mb-2 text-center text-4xl font-semibold">
        Complete Your Profile
      </h2>
      <p className="mb-6 text-center text-lg text-gray-600">
        To access all the features of our website, please complete your profile.
        This will help other users find and contact you for cleaning services.
      </p>

      {/* Steps */}
      <div className="mb-6 flex w-full items-center justify-between">
        <div className="flex flex-col items-center text-center">
          <PhoneIcon className="mb-2 h-24 w-24 text-yellow-500" />
          <h4 className="text-lg font-medium">Fill Out Your Profile</h4>
        </div>

        {/* Линия между шагами */}
        <div className="h-0.5 w-12 bg-gray-300"></div>

        <div className="flex flex-col items-center text-center">
          <MailIcon className="mb-2 h-24 w-24 text-yellow-500" />
          <h4 className="text-lg font-medium">Proceed to Application</h4>
        </div>

        {/* Линия между шагами */}
        <div className="h-0.5 w-12 bg-gray-300"></div>

        <div className="flex flex-col items-center text-center">
          <LaptopIcon className="mb-2 h-24 w-24 text-yellow-500" />
          <h4 className="text-lg font-medium">Confirmed & Done</h4>
        </div>
      </div>

      {/* Button */}
      <button
        className="text-white mt-6 rounded-lg bg-yellow-500 px-6 py-2 font-medium transition hover:bg-yellow-600"
        onClick={handleComplete}
      >
        Fill Out My Profile
      </button>
    </div>
  );
};
