import React, { FC } from "react";
import { Logo } from "../Logo";
import { NAVIGATION_LINKS, SERVICES_LINKS } from "./constants";
import { ContactUs } from "./ContactUs";
import { CopyRight } from "./CopyRight";
import { Navigation } from "./Navigation";

interface Props {
  isShown?: boolean;
}

export const Footer: FC<Props> = ({ isShown = true }) => {
  if (!isShown) return null;

  return (
    <footer className="bg-gray-dark">
      <div className="container py-8">
        <div className="flex flex-col gap-24 lg:flex-row">
          <div className="flex w-full flex-col items-center gap-5 lg:w-[300px] lg:flex-row lg:items-start">
            <Logo />

            <p className="mt-3 text-center text-white-base">
              Leo elementum iaculis quam massa vitae odio sed. Morbi tincidunt
              senectus.
            </p>
          </div>

          <div className="mt-8 hidden flex-1 gap-15 lg:flex">
            <Navigation {...NAVIGATION_LINKS} />
            <Navigation {...SERVICES_LINKS} />
            <ContactUs />
          </div>
          <div className="mt-8 w-full flex-1 flex-col justify-center gap-15 lg:hidden">
            <div className="mb-5 flex gap-15">
              <Navigation {...NAVIGATION_LINKS} />
              <Navigation {...SERVICES_LINKS} />
            </div>
            <ContactUs />
          </div>
        </div>

        <hr className="mb-8 mt-14 border-yellow-base" />

        <CopyRight />
      </div>
    </footer>
  );
};
