import React, { FC } from "react";
import cn from "classnames";
import Error from "src/components/Error";
import { ReactComponent as CheckIcon } from "src/assets/icons/check.svg";

interface CheckboxProps {
  name: string;
  label?: string;
  checked: boolean;
  labelClassName?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  labelClassName,
  checked,
  onChange,
  error,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          "relative flex h-6 w-6 items-center justify-center rounded-md border border-yellow-base",
          {
            "bg-yellow-base": checked,
            "bg-transparent": !checked,
          }
        )}
      >
        <input
          id={name}
          type="checkbox"
          className="absolute inset-0 z-10 cursor-pointer opacity-0"
          onChange={handleChange}
        />

        {checked && <CheckIcon />}
      </div>

      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}

      {error && <Error isShownError={true}>{error}</Error>}
    </div>
  );
};
