import PageBanner from "src/page-components/PageBanner";
import ServiceItem from "src/page-components/services/ServiceItem";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import Service1Image from "src/assets/images/services/list/block1.png";
import Service2Image from "src/assets/images/services/list/block2.png";
import Service3Image from "src/assets/images/services/list/block3.png";
import Service4Image from "src/assets/images/services/list/block4.png";

const servicesData = [
  {
    id: 1,
    image: Service1Image,
    title: "Basic Cleaning",
    subtitle: "Essential Care for Your Home",
    description:
      "Ideal for regular upkeep, our Basic Cleaning service ensures that your home stays in top shape without the hassle. Enjoy more free time while we take care of the cleaning!",
    link: "#",
  },
  {
    id: 2,
    image: Service2Image,
    title: "Deep Cleaning",
    subtitle: "Thorough and Detailed Cleaning",
    description:
      "Revitalize your home with a meticulous deep clean that leaves every corner spotless. Experience the difference of a truly clean space!",
    link: "#",
  },
  {
    id: 3,
    image: Service3Image,
    title: "Post Construction",
    subtitle: "Transform Your Renovation into a Clean Space",
    description:
      "Ensure your newly renovated space is safe and welcoming. Our thorough cleaning will leave your property looking immaculate and ready for use.",
    link: "#",
  },
  {
    id: 4,
    image: Service4Image,
    title: "Move Out Cleaning",
    subtitle: "Leave Your Old Home Spotless",
    description:
      "Moving can be stressful, but we’ll take care of the cleaning so you can focus on your new adventure. Rest assured that your old home will shine for its next occupants!",
    link: "#",
  },
];

const Services = () => {
  return (
    <PageWrapper mainClassName="flex flex-col gap-[100px] items-center pb-[100px]">
      <PageBanner
        className="w-full bg-services-banner bg-cover bg-center bg-no-repeat"
        title="Our Cleaning Services"
        description="At Sparkle the Cleaning Service, we offer a range of professional cleaning services designed to meet your specific needs."
      />

      <div className="container grid grid-cols-1 gap-8 lg:grid-cols-2">
        {servicesData.map((service) => (
          <ServiceItem
            key={service.id}
            image={service.image}
            title={service.title}
            subtitle={service.subtitle}
            description={service.description}
            link={service.link}
          />
        ))}
      </div>
    </PageWrapper>
  );
};

export default Services;
