import React, { FC } from "react";
import cn from "classnames";

// import { ReactComponent as CloseIcon } from "src/assets/icons/x-mark.svg";

interface Props {
  className?: string;
  isActiveDefaultHoverEffect?: boolean;
  onClose: VoidFunction;
}

export const CloseButton: FC<Props> = ({
  className,
  isActiveDefaultHoverEffect = true,
  onClose,
}) => (
  <button
    className={cn("group z-30 rounded-lg bg-white-base", className)}
    onClick={onClose}
  >
    {/* <CloseIcon
      className={cn("w-full h-full transition-all duration-200 opacity-50", {
        "group-hover:opacity-100": isActiveDefaultHoverEffect,
      })}
    /> */}
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.4984 9.51509C30.3442 9.36058 30.1611 9.238 29.9595 9.15437C29.7578 9.07073 29.5417 9.02768 29.3234 9.02768C29.1051 9.02768 28.889 9.07073 28.6874 9.15437C28.4858 9.238 28.3026 9.36058 28.1484 9.51509L19.9984 17.6484L11.8484 9.49842C11.6941 9.34412 11.5109 9.22172 11.3093 9.13821C11.1077 9.0547 10.8916 9.01172 10.6734 9.01172C10.4552 9.01172 10.2391 9.0547 10.0375 9.13821C9.83591 9.22172 9.65272 9.34412 9.49842 9.49842C9.34412 9.65272 9.22172 9.83591 9.13821 10.0375C9.0547 10.2391 9.01172 10.4552 9.01172 10.6734C9.01172 10.8916 9.0547 11.1077 9.13821 11.3093C9.22172 11.5109 9.34412 11.6941 9.49842 11.8484L17.6484 19.9984L9.49842 28.1484C9.34412 28.3027 9.22172 28.4859 9.13821 28.6875C9.0547 28.8891 9.01172 29.1052 9.01172 29.3234C9.01172 29.5416 9.0547 29.7577 9.13821 29.9593C9.22172 30.1609 9.34412 30.3441 9.49842 30.4984C9.65272 30.6527 9.83591 30.7751 10.0375 30.8586C10.2391 30.9421 10.4552 30.9851 10.6734 30.9851C10.8916 30.9851 11.1077 30.9421 11.3093 30.8586C11.5109 30.7751 11.6941 30.6527 11.8484 30.4984L19.9984 22.3484L28.1484 30.4984C28.3027 30.6527 28.4859 30.7751 28.6875 30.8586C28.8891 30.9421 29.1052 30.9851 29.3234 30.9851C29.5416 30.9851 29.7577 30.9421 29.9593 30.8586C30.1609 30.7751 30.3441 30.6527 30.4984 30.4984C30.6527 30.3441 30.7751 30.1609 30.8586 29.9593C30.9421 29.7577 30.9851 29.5416 30.9851 29.3234C30.9851 29.1052 30.9421 28.8891 30.8586 28.6875C30.7751 28.4859 30.6527 28.3027 30.4984 28.1484L22.3484 19.9984L30.4984 11.8484C31.1318 11.2151 31.1318 10.1484 30.4984 9.51509Z"
        fill="black"
      />
    </svg>
  </button>
);
