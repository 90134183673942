import { AxiosError } from "axios";
import Cookies from "js-cookie";
import { instance } from "src/services/api-client";
import { history } from "src/services/history";
import { isTokenExpired } from "src/services/interceptors";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { NotificationService } from "src/helpers/notifications";
import { ACCESS_TOKEN, AUTH_REFRESH_TOKEN } from "src/constants/cookiesKeys";
import { PATHNAMES } from "src/constants/routes";
import { IAuthTokens } from "src/@types/auth";

interface LoginParams {
  email: string;
  password: string;
}

interface RegisterParams {
  email: string;
  password: string;
  type: string;
}

interface IAuthStore {
  email: string;
  activeUserInfo: any;
  getActiveUser: () => void;
  setAuthEmail: (email: string) => void;
  login: (
    values: LoginParams,
    onSuccess: () => void,
    onError?: (error: AxiosError) => void
  ) => void;
  register: (
    values: RegisterParams,
    onSuccess: () => void,
    onError?: (error: AxiosError) => void
  ) => void;
  logout: () => void;
}

const useAuthStore = create(
  devtools<IAuthStore>((set) => ({
    email: "",
    setAuthEmail: (email: string) => set({ email }),

    login: async (values, onSuccess, onError) => {
      try {
        const { data } = await instance.post<IAuthTokens>(
          "/api/auth/login",
          values
        );

        Cookies.set(ACCESS_TOKEN, data.access_token);
        Cookies.set(AUTH_REFRESH_TOKEN, data.refresh_token);

        onSuccess();
        // NotificationService.success();
      } catch (error) {
        const axiosError = error as AxiosError;
        onError(axiosError);
        // NotificationService.error(errorText);
      }
    },
    activeUserInfo: null,
    getActiveUser: async () => {
      try {
        const refreshToken = Cookies.get(AUTH_REFRESH_TOKEN);
        const isRefreshTokenExpired = isTokenExpired(refreshToken);

        if (isRefreshTokenExpired) return null;

        const { data } = await instance.get("/api/auth/me");

        set({ activeUserInfo: data });
      } catch ({ response }) {
        const errorText = response?.data?.error;
        NotificationService.error(errorText);
      }
    },
    register: async (values, onSuccess, onError) => {
      try {
        const response = await instance.post<IAuthTokens>(
          "/api/auth/register",
          values
        );

        onSuccess();
      } catch (error) {
        const axiosError = error as AxiosError;
        onError(axiosError);
      }
    },
    logout: () => {
      Cookies.remove(ACCESS_TOKEN);
      Cookies.remove(AUTH_REFRESH_TOKEN);
      history.replace(PATHNAMES.HOME);
    },
  }))
);

export default useAuthStore;
