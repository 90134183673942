import React, { useState } from "react";
import cn from "classnames";
import { ReactComponent as StarOutlineIcon } from "src/assets/icons/star-rating-outline.svg";

interface IRatingPickerProps {
  rating: number;
  setRating: (rating: number) => void;
}

const RatingPicker = ({ rating, setRating }: IRatingPickerProps) => {
  const totalStars = 5;
  // const [rating, setRating] = useState(null);
  const [hover, setHover] = useState(null);

  return (
    <div className="flex items-center gap-1">
      {[...Array(totalStars)].map((star, index) => {
        const currentRating = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              className="hidden"
              value={currentRating}
              onChange={() => setRating(currentRating)}
            />

            <StarOutlineIcon
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
              className={cn(
                "cursor-pointer transition-all",
                currentRating <= (hover || rating)
                  ? "text-orange-strong"
                  : "text-transparent"
              )}
            />

            {/* <span
              className="m-2 cursor-pointer text-2xl"
              style={{
                color:
                  currentRating <= (hover || rating) ? "#ffc107" : "#e4e5e9",
              }}
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            >
              &#9733;
            </span> */}
          </label>
        );
      })}
    </div>
  );
};

export default RatingPicker;
