import React, { FC } from "react";
import { SectionHeader } from "src/components/SectionHeader";
import { OurTeamList } from "./OurTeamList";

export const OurTeam: FC = () => (
  <section className="bg-blue-ligther">
    <div className="container flex flex-col items-center gap-16 py-24">
      <SectionHeader
        title="Choose Your Cleaner Or Team"
        subtitle="Our Team"
        isCenter
      />

      <OurTeamList />
    </div>
  </section>
);
