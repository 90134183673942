import React, { FC } from "react";
import cn from "classnames";

export interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  name: string;
  options: Option[];
  value?: string | number;
  placeholder?: string;
  onChange: (value: string | number) => void;
  className?: string;
  isDisabled?: boolean;
  label?: string; // Добавляем пропс для label
}

export const Select: FC<SelectProps> = ({
  name,
  options,
  value,
  placeholder = "Select an option",
  onChange,
  className,
  isDisabled = false,
  label, // Используем пропс для отображения
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(event.target.value);
  };

  return (
    <div className={cn("relative", className)}>
      {label && (
        <label
          htmlFor={name}
          className="mb-2 block text-sm font-medium text-gray-700"
        >
          {label}
        </label>
      )}
      <select
        id={name} // Добавляем id для привязки к label
        name={name}
        value={value}
        onChange={handleChange}
        disabled={isDisabled}
        className={cn(
          "w-full rounded-md border px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500",
          isDisabled && "cursor-not-allowed bg-gray-200",
          className
        )}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
