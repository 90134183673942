import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import NumberedRating from "src/components/Rating/NumberedRating";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as RatingStarIcon } from "src/assets/icons/star-rating.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";
import CleanerImage from "src/assets/images/cleaner-item2.png";
import { Sizes } from "src/@types/sizes";
import BookingBadge from "./RequestBadge";

interface IRequestItemProps {
  inHistory?: boolean;
}

const RequestItem = ({ inHistory }: IRequestItemProps) => {
  return (
    <>
      {/* MOBILE */}
      <div className="flex items-start justify-between gap-6 border-b border-[#D1D1DC] pb-6 pl-5 lg:hidden">
        <img src={CleanerImage} className="h-18 w-18" alt="cleaner" />
        <div className="flex flex-1 gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex flex-col items-start gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                Basic Cleaning
              </div>

              {!inHistory && <BookingBadge status="approved" />}
              <div className="flex flex-col items-start justify-between">
                <div className="flex items-center gap-2">
                  <NumberedRating rating={4.8} />

                  <p>Client: Peter Parker</p>
                </div>
                <div>
                  Address: Apt. 570 544 Norbert Rue, Catheyton, CT 28557-0311{" "}
                </div>
                <div>09-02-2024</div>
              </div>
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                $140
              </div>

              <div className="block lg:hidden">
                <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE_MOBILE}>
                  <Button
                    size={Sizes.S}
                    variant={
                      inHistory
                        ? ButtonVariants.OPACITY_PRIMARY
                        : ButtonVariants.PRIMARY
                    }
                    className="w-[225px]"
                  >
                    {inHistory ? "Review" : "Contact"}
                  </Button>
                </Link>
              </div>
              <div className="hidden lg:block">
                <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE}>
                  <Button
                    size={Sizes.S}
                    variant={
                      inHistory
                        ? ButtonVariants.OPACITY_PRIMARY
                        : ButtonVariants.PRIMARY
                    }
                    className="w-[225px]"
                  >
                    {inHistory ? "Review" : "Accept"}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            {!inHistory && (
              <button onClick={() => {}}>
                <TrashIcon />
              </button>
            )}
          </div>
        </div>
      </div>
      {/* DESKTOP */}
      <div className="hidden items-center justify-between gap-6 border-b border-[#D1D1DC] pb-6 lg:flex">
        <img src={CleanerImage} className="h-18 w-18" alt="cleaner" />
        <div className="flex flex-1 flex-col gap-1">
          <div className="flex flex-col items-center justify-between lg:flex-row">
            <div className="flex items-center gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                Basic Cleaning
              </div>

              {!inHistory && <BookingBadge status="approved" />}
            </div>

            <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
              $140
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <NumberedRating rating={4.8} />

              <p>Client: Peter Parker</p>
            </div>
            <div>
              Address: Apt. 570 544 Norbert Rue, Catheyton, CT 28557-0311{" "}
            </div>
            <div>09-02-2024</div>
          </div>
        </div>

        <div className="w-full max-w-[165px]">
          <div className="flex items-center gap-4">
            <Link to={PATHNAMES.ACCOUNT_REVIEW_CREATE}>
              <Button
                size={Sizes.S}
                variant={
                  inHistory
                    ? ButtonVariants.OPACITY_PRIMARY
                    : ButtonVariants.PRIMARY
                }
                className="w-full"
              >
                {inHistory ? "Review" : "Contact"}
              </Button>
            </Link>

            {!inHistory && (
              <button onClick={() => {}}>
                <TrashIcon />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestItem;
