import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import { TextInput } from "src/ui/TextInput";
import { ChangeEmail } from "src/page-components/ChangeEmail/ChangeEmail";
import { ConfirmationModal } from "src/page-components/ChangeEmail/ConfirmationModal";
import { EmailSuccess } from "src/page-components/ChangeEmail/EmailSuccess";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { RESET_PASSWORD_FORM_FIELDS } from "../ResetPassword/constants";
import {
  PERSONAL_DATA_FORM_FIELDS,
  PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
  PERSONAL_DATA_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

export const EditForm: FC = () => {
  const { user, type, updateField, isChangingPersonalData, updateUserField } =
    useClientStore((state) => state);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [newEmailAddress, setNewEmailAddress] = React.useState("");
  const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
  const [isSuccesModalOpen, setIsSuccesModalOpen] = React.useState(false);

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: {
      name: user.name || "",
      email: user.email || "",
      phone_number: user.phone_number || "",
      state: user.state || "",
      address: user.address || "",
    },
    validationSchema: PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      // Remove spaces from the phone number
      const cleanedPhoneNumber = values.phone_number.replace(/\s+/g, "");

      // Update the phone number in the form values
      const updatedValues = {
        ...values,
        phone_number: cleanedPhoneNumber,
      };

      const updatedFields = getUpdatedFields(
        formikProps.initialValues,
        updatedValues
      );

      if (Object.keys(updatedFields).length > 0) {
        const dataInfo = {
          id: user.id,
          ...updatedFields, // Only changed fields
        };

        console.log("dataInfo", dataInfo);
        clientServices.updateClient(dataInfo); // Send only changed fields
      } else {
        console.log("No changes to save.");
      }
    },
  };

  const formik = useFormik(formikProps);

  const getUpdatedFields = (
    initialValues: IFormikValues,
    currentValues: IFormikValues
  ) => {
    debugger;
    const updatedFields: Partial<IFormikValues> = {};
    Object.keys(initialValues).forEach((key) => {
      if (
        initialValues[key as keyof IFormikValues] !==
        currentValues[key as keyof IFormikValues]
      ) {
        updatedFields[key as keyof IFormikValues] =
          currentValues[key as keyof IFormikValues];
      }
    });
    return updatedFields;
  };

  const handleIsChangingPersonalData = (value: boolean) => {
    updateField("isChangingPersonalData", value);
  };

  return (
    <>
      <div className="flex w-full justify-end">
        <p
          className="mr-5 cursor-pointer text-sm font-semibold text-[#F4B10B] underline lg:text-lg"
          onClick={() => setIsModalOpen(true)}
        >
          Change
        </p>
      </div>
      <FormikProvider value={formik}>
        <Form className="">
          <div className="ml-2 flex w-[90%] flex-col gap-6 lg:grid lg:w-auto lg:grid-cols-4">
            <RenderFormFields
              className="!rounded-[10px]"
              fields={PERSONAL_DATA_FORM_FIELDS}
              disabled={!isChangingPersonalData}
            />
          </div>
          {isChangingPersonalData && (
            <div className="flex gap-6">
              <RenderFormFields
                disabled={!isChangingPersonalData}
                fields={RESET_PASSWORD_FORM_FIELDS}
              />
            </div>
          )}

          <div className="mt-10">
            {isChangingPersonalData ? (
              <div className="flex justify-between">
                <Button
                  onClick={() => handleIsChangingPersonalData(false)}
                  size={Sizes.S}
                  variant={ButtonVariants.CANCEL}
                  type="button"
                >
                  Cancel
                </Button>

                <Button
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                  type="submit"
                  // onClick={hanleSaveChanges}
                >
                  Save Changes
                </Button>
              </div>
            ) : (
              <div className="flex justify-end">
                <Button
                  onClick={() => handleIsChangingPersonalData(true)}
                  size={Sizes.S}
                  variant={ButtonVariants.PRIMARY}
                >
                  Change information
                </Button>
              </div>
            )}
          </div>
        </Form>
      </FormikProvider>

      <ChangeEmail
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        newEmailAddress={newEmailAddress}
        setNewEmailAddress={setNewEmailAddress}
        setIsConfirmationModalOpen={setIsConfirmModalOpen}
      />
      <ConfirmationModal
        isModalOpen={isConfirmModalOpen}
        setIsModalOpen={setIsConfirmModalOpen}
        newEmailAddress={newEmailAddress}
        setIsNextModalOpen={setIsSuccesModalOpen}
      />
      <EmailSuccess
        isModalOpen={isSuccesModalOpen}
        setIsModalOpen={setIsSuccesModalOpen}
        newEmailAddress={newEmailAddress}
      />
    </>
  );
};
