import React, { useEffect, useState } from "react";
import { ReactComponent as FileIcon } from "src/assets/icons/file.svg";
import { ReactComponent as TrashIcon } from "src/assets/icons/trash.svg";

interface IFileUploaderProps {
  value?: File | string | null;
  setValue?: (value: File | null) => void;
  title?: string;
  label?: string;
  name?: string;
  acceptedFileTypes?: string[];
}

const FileUploader = ({
  value,
  name,
  setValue,
  label = "Your file",
  title = "Attach your file here",
  acceptedFileTypes = [],
}: IFileUploaderProps) => {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      if (
        acceptedFileTypes.length &&
        !acceptedFileTypes.includes(selectedFile.type)
      ) {
        alert("Invalid file type. Please upload a valid file.");
        return;
      }
      setValue(selectedFile);
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files
      ? event.dataTransfer.files[0]
      : null;
    if (droppedFile) {
      if (
        acceptedFileTypes.length &&
        !acceptedFileTypes.includes(droppedFile.type)
      ) {
        alert("Invalid file type. Please upload a valid file.");
        return;
      }
      setValue(droppedFile);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  return (
    <>
      {value ? (
        <div className="flex flex-col gap-3">
          <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
            {label}
          </div>

          <div className="flex w-1/2 items-center justify-between">
            <div className="flex items-center gap-2">
              <FileIcon />
              <div>{typeof value === "string" ? name : value.name}</div>
            </div>

            <button
              onClick={() => {
                setValue(null);
              }}
              className="flex items-center gap-2 text-sm text-red-600"
            >
              Delete
              <TrashIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      ) : (
        <div
          className="relative flex h-40 w-full flex-col items-center justify-center rounded-[10px] border-2 border-[#D0D5DD] bg-[#D0D5DD33] p-4 text-center"
          onDrop={handleDrop}
          onDragOver={handleDragOver}
        >
          <>
            <p className="text-lg text-yellow-base">{title}</p>
            <p className="text-sm text-[#21233199]">or just drag & drop</p>
            <input
              type="file"
              onChange={handleFileChange}
              className="absolute inset-0 w-full cursor-pointer opacity-0"
              accept={acceptedFileTypes.join(",")}
            />
          </>
        </div>
      )}
    </>
  );
};

export default FileUploader;
