import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "src/components/Avatar";
import { Rating } from "src/components/Rating";
import { IReviewCard } from "./types";

export const ReviewsCard: FC<IReviewCard> = ({
  rating,
  review,
  avatar,
  name,
  description,
  url,
}) => (
  <article className="w-[364px] rounded-xl border pt-8">
    <Rating className="px-8" rating={rating} />

    <p className="border-b px-8 pb-3.5 pt-5">{review}</p>

    <Link
      to={url}
      className="flex items-center gap-3.5 px-8 py-4 transition-all duration-200 hover:bg-blue-ligth"
    >
      <Avatar avatarUrl={avatar} />

      <div>
        <span className="text-lg font-semibold text-black-medium">{name}</span>
        <p>{description}</p>
      </div>
    </Link>
  </article>
);
