import React from "react";

export interface ITimeInputProps {
  id?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  fieldClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  icon?: React.ReactNode;
  isShownError?: boolean;
  error?: string;
}

const TimeInput = ({
  icon,
  name,
  id,
  label,
  value,
  onChange,
  disabled,
}: ITimeInputProps) => {
  return (
    <div>
      <label
        htmlFor={id}
        className="mb-2 block text-sm font-medium text-gray-900"
      >
        Start time:
      </label>
      <div className="relative">
        <div className="pointer-events-none absolute inset-y-0 end-0 top-0 flex items-center pe-3.5">
          {icon && icon}
        </div>
        <input
          type="time"
          id={id}
          className="block w-full rounded-lg border border-[#D0D5DD] bg-transparent p-2.5 text-sm leading-none text-gray-900"
          min="09:00"
          max="18:00"
          value={value}
          onChange={onChange}
          required
        />
      </div>
    </div>
  );
};

export default TimeInput;
