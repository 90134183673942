import React, { FC } from "react";
import { IOptionSelect, SetOptionSelect } from "src/@types/form";

interface ISortOptionProps {
  option: IOptionSelect;
  setOption: SetOptionSelect;
  labelKey: string;
  valueKey: string;
}

// export const SortOption: FC<Props> = ({ option, setOption }) => {
//   const onClick = () => setOption(option);

//   return (
//     <li
//       className="cursor-pointer border-t px-4 py-3 transition-all duration-200 hover:bg-black-ligth hover:text-white-base"
//       onClick={onClick}
//     >
//       {option.label}
//     </li>
//   );
// };

export const SortOption: FC<ISortOptionProps> = ({
  option,
  setOption,
  labelKey,
  valueKey,
}) => {
  const onClick = () => setOption(option);

  const getLabel = (option: IOptionSelect) =>
    option[labelKey as keyof IOptionSelect] as string;

  return (
    <li
      className="cursor-pointer border-t px-4 py-3 transition-all duration-200 hover:bg-black-ligth hover:text-white-base"
      onClick={onClick}
    >
      {getLabel(option)}
    </li>
  );
};
