import React, { FC, KeyboardEvent, useMemo } from "react";
import PhoneInput from "react-phone-number-input/input";
import MaskedInput from "react-text-mask";
import cn from "classnames";
import { useField, useFormikContext } from "formik";
import { IFormField } from "src/@types/form";
import { FormField } from ".";
import { TEXT_INPUT_STYLE_VARIANTS } from "./constants";
import { FormFieldVariants } from "./types";

export const TextInput: FC<IFormField> = ({
  className,
  fieldClassName,
  labelClassName,
  id,
  label,
  variant = FormFieldVariants.PRIMARY,
  placeholder,
  isTextArea,
  disabled,
  onChange,
  value,
  maxLength,
  ...props
}) => {
  const fieldId = id || props.name;

  const { handleSubmit } = useFormikContext();
  const [
    { value: valueFormik, onChange: formikOnChange, ...field },
    { error, touched },
    { setValue },
  ] = useField(fieldId);
  const isShownError = Boolean((touched || value) && error);

  const onKeyDownEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit();
    }
  };

  const combinedClassNames = cn(
    "w-full outline-none disabled:bg-[#D0D5DD33] disabled:cursor-default disabled:border-[#D0D5DD33]",
    TEXT_INPUT_STYLE_VARIANTS[variant],
    className,
    { "resize-y": isTextArea }
  );

  // const Tag = isTextArea ? "textarea" : "input";
  // const InputComponent = props?.mask ? MaskedInput : Tag;

  const isTelField = props?.type === "tel";

  const InputComponent = useMemo(() => {
    if (isTelField) {
      return PhoneInput;
    }
    if (props?.mask) {
      return MaskedInput;
    } else if (isTextArea) {
      return "textarea";
    } else {
      return "input";
    }
  }, [props?.mask, isTextArea, isTelField]);

  const handlePhoneChange = (value: string) => {
    const e = {
      target: {
        value,
        name: fieldId,
      },
    } as React.ChangeEvent<HTMLInputElement>;

    console.log(e);
    setValue(value);
    if (onChange) {
      onChange(e);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formikOnChange(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <FormField
      className={fieldClassName}
      labelClassName={labelClassName}
      variant={variant}
      label={label}
      labelFor={fieldId}
      isShownError={isShownError}
      error={error}
    >
      <InputComponent
        id={fieldId}
        className={combinedClassNames}
        value={value ? value : valueFormik}
        disabled={disabled}
        placeholder={placeholder}
        maxLength={isTelField ? 20 : maxLength}
        //@ts-ignore
        onChange={isTelField ? handlePhoneChange : handleChange}
        {...props}
        {...field}
        // Placed here to override the Formik default onKeyDown function
        onKeyDown={onKeyDownEnter}
      />
    </FormField>
  );
};
