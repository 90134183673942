import React, { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Auth } from "src/page-components/sing-in/Auth";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { useModal } from "src/hooks/useModal";

const SignIn: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isOpenModal, openModal, closeModal } = useModal();

  useEffect(() => {
    const isVerified = searchParams.get("verified");
    if (isVerified && isVerified.toLowerCase() === "true") {
      openModal();
    }
  }, [searchParams, openModal]);

  const handleCloseModal = () => {
    closeModal();
    setSearchParams((prevParams) => {
      prevParams.delete("verified");
      return prevParams;
    });
  };

  return (
    <PageWrapper isShownHeader={false} isShownFooter={false}>
      <Auth />

      <ModalWindow
        isShownCloseButton={false}
        isOpen={isOpenModal}
        onClose={handleCloseModal}
      >
        <ModalPrimaryTemplate onClose={handleCloseModal}>
          <div>
            <h1 className="text-center text-5xl font-bold">Confirmed 🎉</h1>
            <p className="mt-4 text-center text-2xl">
              Email verification is successful! Now you can get started by
              logging in.
            </p>
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </PageWrapper>
  );
};

export default SignIn;
