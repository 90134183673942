import React from "react";

interface Props {
  isOpen: boolean;
  onClick: () => void;
}

export const BurgerButton: React.FC<Props> = ({ isOpen, onClick }) => (
  <button
    className="bg-white flex h-10 w-10 items-center justify-center rounded-full"
    onClick={onClick}
  >
    <div className="sr-only">{isOpen ? "Close menu" : "Open menu"}</div>
    <div
      aria-hidden="true"
      className={`absolute h-0.5 w-5 bg-white-base transition duration-300 ease-in-out ${
        isOpen ? "rotate-45" : "-translate-y-1.5"
      }`}
    />
    <div
      aria-hidden="true"
      className={`absolute h-0.5 w-5 bg-white-base transition duration-300 ease-in-out ${
        isOpen ? "opacity-0" : "opacity-100"
      }`}
    />
    <div
      aria-hidden="true"
      className={`absolute h-0.5 w-5 bg-white-base transition duration-300 ease-in-out ${
        isOpen ? "-rotate-45" : "translate-y-1.5"
      }`}
    />
  </button>
);
