import React, { FC } from "react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { SectionHeader } from "src/components/SectionHeader";
import { Sizes } from "src/@types/sizes";
import { OurServicesList } from "./OurServicesList";

export const OurServices: FC = () => (
  <section>
    <div className="container pb-18 pt-25">
      <div className="flex items-center justify-between">
        <SectionHeader
          title="Services We Providen"
          subtitle="Our Services"
          description="Faucibus commodo a aenean et sit quisque ipsum. Consequat eu id ut
            dolor felis quis. Sagittis a sapien pulvinar etiam."
        />

        <div className="hidden lg:block">
          <Button variant={ButtonVariants.PRIMARY} size={Sizes.S}>
            All Services
          </Button>
        </div>
      </div>

      <OurServicesList />

      <div className="block lg:hidden">
        <Button
          variant={ButtonVariants.PRIMARY}
          size={Sizes.S}
          className="w-full"
        >
          All Services
        </Button>
      </div>
    </div>
  </section>
);
