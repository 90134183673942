import React, { FC, useState } from "react";
import { useNavigate } from "react-router";
import BlockTitle from "src/page-components/account/BlockTitle";
import RequestItem from "src/page-components/account/bookings/RequestItem";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { ReviewsList } from "src/page-components/home/Reviews/ReviewsList";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { Header } from "src/components/Header";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Rating } from "src/components/Rating";
import NumberedRating from "src/components/Rating/NumberedRating";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { Sizes } from "src/@types/sizes";

const Review: FC = () => {
  const [showHistory, setShowHistory] = useState(true);
  const [showReviewHistory, setReviewHistory] = useState(false);
  const navigate = useNavigate();

  const toggleHistory = () => {
    setShowHistory((prev) => !prev);
  };

  const toggleReviewHistory = () => {
    setReviewHistory((prev) => !prev);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div className="flex flex-col gap-10 pt-[54px]">
        <div className="flex w-full justify-between p-2 lg:hidden">
          <Title title="Reviews" subtitle="" />
          <p onClick={handleBackClick}>Back</p>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-6">
            <div className="flex items-center justify-between px-4 lg:px-0">
              <BlockTitle title="My Reviews" />

              <NumberedRating
                className="gap-2"
                starClassName="w-6 h-6"
                numberClassName="text-2xl font-semibold leading-7"
                rating={4.8}
              />
            </div>

            <ReviewsList />
          </div>

          <Button
            size={Sizes.S}
            variant={ButtonVariants.PRIMARY}
            className="w-[90%] self-center lg:w-auto lg:self-end"
          >
            See More
          </Button>
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex w-full justify-between">
            <BlockTitle title="Booking History" />
            <button
              onClick={toggleHistory}
              className="text-sm font-medium text-blue-500 hover:underline"
            >
              <ArrowIcon />
            </button>
          </div>

          {showHistory && (
            <div className="flex flex-col gap-6">
              <RequestItem inHistory />
              <RequestItem inHistory />
              <RequestItem inHistory />
              <RequestItem inHistory />
            </div>
          )}
        </div>

        <div className="flex flex-col gap-6">
          <div className="flex w-full items-center justify-between">
            <BlockTitle title="Reviews history" />

            <button
              onClick={toggleReviewHistory}
              className="text-sm font-medium text-blue-500 hover:underline"
            >
              <ArrowIcon />
            </button>
          </div>
          {showReviewHistory && (
            <div className="flex flex-col gap-6">
              <ReviewsList />
            </div>
          )}
        </div>
      </div>
      <div className="block lg:hidden">
        <Footer />
      </div>
    </>
  );
};

export default Review;
