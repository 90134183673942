import React, { FC } from "react";
import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { PATHNAMES } from "src/constants/routes";

interface NavigationProps {
  isMobile?: boolean;
}

export const Navigation: FC<NavigationProps> = ({ isMobile = false }) => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);

  const NAVIGATION_LINKS = [
    { id: 1, label: "Home", href: PATHNAMES.HOME },
    { id: 2, label: "About Us", href: PATHNAMES.ABOUT },
    { id: 3, label: "Services", href: PATHNAMES.SERVICES },
    ...(isAuthorized
      ? [{ id: 4, label: "Subscriptions", href: PATHNAMES.SUBSCRIPTION }]
      : []),
  ];

  return (
    <ul
      className={`${
        isMobile
          ? "flex flex-col items-center space-y-4 py-4"
          : `${isAuthorized ? "mr-[460px]" : "mr-[370px]"} flex gap-12 whitespace-nowrap text-white-base`
      }`}
    >
      {NAVIGATION_LINKS.map(({ id, label, href }) => (
        <li key={id} className={isMobile ? "py-2" : ""}>
          <Link
            className={`${
              isMobile
                ? "block px-4 text-gray-900"
                : "text-white-base hover:opacity-90"
            }`}
            to={href}
          >
            {label}
          </Link>
        </li>
      ))}
    </ul>
  );
};
