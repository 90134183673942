import { withFormikField } from "src/hoc/withFormikField";
import { TextInput } from "src/ui/TextInput";
import * as Yup from "yup";
import { IRenderFormField } from "src/@types/form";

export const FILLING_INITIAL_VALUES = {
  house_size: "",
  floors: "",
  rooms: "",
  bathrooms: "",
  bed_linen_change: "",
  has_kids: false,
  has_pets: false,
  additional_notes: "",
};

export const FILLING_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  house_size: Yup.string().required("House size is required"),
  floors: Yup.string().required("Floor count is required"),
  rooms: Yup.string().required("Room count is required"),
  bathrooms: Yup.string().required("Bathroom count is required"),
  bed_linen_change: Yup.string().required("Bed linen change is required"),
  has_kids: Yup.boolean(),
  has_pets: Yup.boolean(),
  additional_notes: Yup.string(),
});

const FormikTextInput = withFormikField(TextInput);

export const FILLING_FORM_FIELDS: IRenderFormField[] = [
  {
    name: "house_size",
    type: "text",
    label: "House size",
    component: FormikTextInput,
    labelClassName: "font-medium text-gray-base",
  },
  {
    name: "floors",
    type: "text",
    label: "Floor count",
    component: FormikTextInput,

    labelClassName: "font-medium text-gray-base",
  },
  {
    name: "rooms",
    type: "text",
    label: "Room count",
    component: FormikTextInput,

    labelClassName: "font-medium text-gray-base",
  },
  {
    name: "bathrooms",
    type: "text",
    label: "Bathroom count",
    component: FormikTextInput,

    labelClassName: "font-medium text-gray-base",
  },
];
