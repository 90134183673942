import React, { FC } from "react";
import { AboutUs } from "src/page-components/home/AboutUs";
import { HeroSection } from "src/page-components/home/HeroSection";
import { HowItWorks } from "src/page-components/home/HowItWorks";
import { OurServices } from "src/page-components/home/OurServices";
import { OurTeam } from "src/page-components/home/OurTeam";
import { Reviews } from "src/page-components/home/Reviews";
import { Video } from "src/page-components/home/Video";
import { WhyChooseUs } from "src/page-components/home/WhyChooseUs";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { SubscriptionSection } from "src/components/Subscription";

const Home: FC = () => {
  return (
    <PageWrapper>
      <HeroSection />
      <HowItWorks />
      <AboutUs />
      <Video />
      <OurServices />
      <WhyChooseUs />
      {/* <SubscriptionSection /> */}
      <Reviews />
      <OurTeam />
    </PageWrapper>
  );
};

export default Home;
