import React from "react";

interface IEmailConfirmationProps {
  title: string;
  message: string;
}

const NotificationItem = ({ title, message }: IEmailConfirmationProps) => {
  return (
    <div>
      <h1 className="text-center text-xl font-bold lg:text-5xl">{title}</h1>
      <p className="text-md mt-4 text-center lg:text-2xl">{message} </p>
    </div>
  );
};

export default NotificationItem;
