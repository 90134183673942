export const DEFAULT_CONTAINER_BUTTONS_CLASSNAME =
  "flex justify-center items-center mt-2";

export const DEFAULT_MODAL_BUTTON_CLASSNAME = "";

export enum ModalNotificationType {
  REGISTER_SUCCESS = "REGISTER_SUCCESS",
  EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",
  EMAIL_EXISTS = "EMAIL_EXISTS",
  GENERAL_ERROR = "GENERAL_ERROR",
}
