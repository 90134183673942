import React, { FC } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ReviewsCard } from "./ReviewsCard";
import { TEMP_REVIEWS_ITEMS } from "./temp/contants";

export const ReviewsList: FC = () => {
  return (
    <>
      <div className="mt-12.5 w-full pl-5">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          className="lg:hidden"
        >
          {TEMP_REVIEWS_ITEMS.map(({ id, ...card }) => (
            <SwiperSlide key={id}>
              <ReviewsCard {...card} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="hidden gap-6 lg:flex">
        {TEMP_REVIEWS_ITEMS.map(({ id, ...card }) => (
          <ReviewsCard key={id} {...card} />
        ))}
      </div>
    </>
  );
};
