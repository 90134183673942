import React, { FC } from "react";
import { Link } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import bookingServices, { ICleaningType } from "src/services/booking-service";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import PhoneInput from "src/ui/PhoneInput";
import Select from "src/ui/Select";
import { TextInput } from "src/ui/TextInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import FileUploader from "src/components/FileUploader";
import { RenderFormFields } from "src/components/RenderFormFields";
import { SortingDropdownList } from "src/components/SortDropdownList";
import { ReactComponent as FileIcon } from "src/assets/icons/file.svg";
import { Sizes } from "src/@types/sizes";
import {
  PERSONAL_DATA_FORM_FIELDS,
  PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
  PERSONAL_DATA_INITIAL_VALUES,
  YEARS_OF_EXPERIENCE_DATA,
} from "./constants";
import { IFormikValues } from "./types";

export const CleanerEditForm: FC = () => {
  const { user, isChangingPersonalData, updateUserField } = useClientStore(
    (state) => state
  );

  const { data: licenseData } = useQuery({
    queryKey: ["getLicense"],
    queryFn: () => {
      clientServices.getLicense().then((res) => {
        updateUserField("license", res.file_url);
      });
    },
  });

  const { data: insuranceData } = useQuery({
    queryKey: ["getInsurance"],
    queryFn: () => {
      clientServices.getInsurance().then((res) => {
        updateUserField("insurance", res.file_url);
      });
    },
  });

  const { data: cleaningTypes, isLoading } = useQuery({
    queryKey: ["cleaningTypes"],
    queryFn: bookingServices.getCleaningTypes,
  });

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: PERSONAL_DATA_INITIAL_VALUES,
    validationSchema: PERSONAL_DATA_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {},
  };

  const formik = useFormik(formikProps);

  const handleInputChange = (
    field: string,
    value: string | number | File | any
  ) => {
    updateUserField(field, value);
  };

  return (
    <FormikProvider value={formik}>
      <Form className="mt-10">
        <div className="grid grid-cols-2 items-stretch gap-6">
          <TextInput
            name="name"
            value={user.name}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Write here"
            label="Name & Surname"
            className="!rounded-10"
            disabled={!isChangingPersonalData}
          />

          <TextInput
            name="email"
            value={user.email}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Write here"
            label="Email"
            className="!rounded-10"
            disabled={true}
          />

          <PhoneInput
            name="phone_number"
            value={user.phone_number}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Write here"
            label="Phone"
            className="!rounded-10"
            disabled={!isChangingPersonalData}
          />

          <TextInput
            name="zip_code"
            value={user.zip_code}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            placeholder="Write here"
            label="Zip code"
            className="!rounded-10"
            disabled={!isChangingPersonalData}
          />

          <div className="col-span-2">
            <TextInput
              name="bio"
              isTextArea
              value={user.bio}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              placeholder="Write here"
              label="Bio"
              className="!rounded-10"
              disabled={!isChangingPersonalData}
            />
          </div>

          {/* <SortingDropdownList
            headLabel="experience"
            placeholder="Choose years of your experience"
            options={YEARS_OF_EXPERIENCE_DATA}
            activeOption={user.experience_years}
            setOption={(value) => handleInputChange("experience_years", value)}
            disabled={!isChangingPersonalData}
          /> */}

          <TextInput
            name="experience_years"
            placeholder="Choose years of your experience"
            value={user.experience_years}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            label="Experience"
            className="!rounded-10"
            disabled={!isChangingPersonalData}
          />

          {isLoading ? (
            <div>Loading...</div>
          ) : (
            <Select
              options={cleaningTypes}
              value={user.cleaning_types}
              getOptionLabel={(option: ICleaningType) => option.name}
              getOptionValue={(option: ICleaningType) => option.id}
              onChange={(value) => handleInputChange("cleaning_types", value)}
              isMulti
              headLabel="Type of Cleaning"
              placeholder="Write here"
              isDisabled={!isChangingPersonalData}
            />
          )}

          <div className="col-span-2 flex flex-col gap-6">
            <div className="">
              {isChangingPersonalData ? (
                <FileUploader
                  value={user.license}
                  setValue={(value) => handleInputChange("license", value)}
                  label="Your License"
                  title="Attach your license here"
                  name="License"
                  acceptedFileTypes={["application/pdf"]}
                />
              ) : typeof user.license === "string" ? (
                <Link
                  className="inline-flex items-center gap-2 text-blue-500"
                  to={user.license}
                >
                  Your License <FileIcon />
                </Link>
              ) : null}
            </div>

            <div className="">
              {isChangingPersonalData ? (
                <FileUploader
                  value={user.insurance}
                  setValue={(value) => handleInputChange("insurance", value)}
                  label="Your Insurance"
                  title="Attach your insurance here"
                  name="Insurance"
                  acceptedFileTypes={["application/pdf"]}
                />
              ) : typeof user.insurance === "string" ? (
                <Link
                  className="inline-flex items-center gap-2 text-blue-500"
                  to={user.insurance}
                >
                  Your insurance <FileIcon />
                </Link>
              ) : null}
            </div>
          </div>
        </div>
      </Form>
    </FormikProvider>
  );
};
