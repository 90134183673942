import React, { FC } from "react";
import cn from "classnames";
import { useField } from "formik";
import { ReactComponent as CheckIcon } from "src/assets/icons/check.svg";
import Error from "../Error";

interface CheckboxProps {
  name: string;
  label?: string;
  checked: boolean;
  labelClassName?: string;
  onChangeValue?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: FC<CheckboxProps> = ({
  name,
  label,
  labelClassName,
  checked,
  onChangeValue,
}) => {
  const [field, meta] = useField({ name, type: "checkbox" });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) {
      onChangeValue(e);
    }
  };

  return (
    <div className="flex items-center gap-2">
      <div
        className={cn(
          "relative flex h-6 w-6 items-center justify-center rounded-md border border-yellow-base",
          {
            "bg-yellow-base": field.checked,
            "bg-transparent": !field.checked,
          }
        )}
      >
        <input
          {...field}
          id={name}
          type="checkbox"
          className="absolute inset-0 z-10 cursor-pointer opacity-0"
          onChange={(e) => {
            field.onChange(e);
            handleChange(e);
          }}
        />

        {field.checked && <CheckIcon />}
      </div>

      {label && (
        <label htmlFor={name} className={labelClassName}>
          {label}
        </label>
      )}

      {meta.touched && meta.error && (
        <Error isShownError={true}>{meta.error}</Error>
      )}
    </div>
  );
};
