import React, { FC, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import { Avatar } from "../Avatar";
import { BurgerButton } from "../BurgerButton";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { Logo } from "../Logo";
import { ActionsButtons } from "./ActionsButtons";
import { Navigation } from "./Navigation";

interface Props {
  isShown?: boolean;
}

export const Header: FC<Props> = ({ isShown = true }) => {
  const { user, isAuthorized } = useClientStore();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const { logout } = useAuthStore((state) => state);

  const handleScrollLock = useCallback(
    (lock: boolean) => {
      if (lock) {
        setScrollPosition(window.scrollY);
        document.body.style.position = "fixed";
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.width = "100%";
      } else {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
        window.scrollTo(0, scrollPosition);
      }
    },
    [scrollPosition]
  );

  const handleBurgerClick = useCallback(() => {
    setIsNavOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleScrollLock(isNavOpen);
    }, 10);

    return () => {
      clearTimeout(timer);
      if (!isNavOpen) {
        document.body.style.position = "";
        document.body.style.top = "";
        document.body.style.width = "";
      }
    };
  }, [isNavOpen, handleScrollLock]);

  if (!isShown) return null;

  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  return (
    <header className="relative bg-blue-dark">
      <div className="container flex items-center justify-between py-3">
        <Logo />
        <div className="hidden items-center gap-12 lg:flex">
          <Navigation />
          <ActionsButtons />
        </div>

        <div className="flex items-center lg:hidden">
          {isAuthorized && user?.avatar ? (
            <Link
              to={cleanWithUsHref}
              className="flex w-full justify-center sm:w-auto"
            >
              <Button
                size={Sizes.S}
                variant={!isAuthorized && ButtonVariants.OPACITY_PRIMARY}
              >
                <Avatar avatarUrl={user?.avatar} />
              </Button>
            </Link>
          ) : (
            <></>
          )}
          <BurgerButton isOpen={isNavOpen} onClick={handleBurgerClick} />
        </div>
      </div>
      <div
        className={`${
          isNavOpen
            ? "pointer-events-auto opacity-100"
            : "pointer-events-none opacity-0"
        } fixed bottom-0 left-0 right-0 top-[102px] z-10 h-screen overflow-hidden bg-white-base transition-opacity duration-300 lg:hidden`}
      >
        <Navigation isMobile />
        <div className="flex w-full justify-center pb-4 pt-2">
          <hr className="w-11/12 border-t border-gray-200" />
        </div>
        <div className="w-full px-6 pt-4">
          <ActionsButtons />
        </div>
        <Button
          variant={ButtonVariants.PRIMARY}
          className="px-5 py-2"
          onClick={() => logout()}
        >
          Logout
        </Button>
      </div>
    </header>
  );
};
