import React, { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";
import { ReactComponent as ArrowDown } from "../../assets/icons/chevron-down.svg";
import { Avatar } from "../Avatar";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";

const NAVIGATION_LINKS = [
  { id: 1, label: "Home", href: PATHNAMES.HOME },
  { id: 2, label: "About Us", href: PATHNAMES.ABOUT },
  { id: 3, label: "Services", href: PATHNAMES.SERVICES },
  { id: 4, label: "Subscriptions", href: PATHNAMES.SUBSCRIPTION },
];

export const ActionsButtons: FC = () => {
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const { user } = useClientStore((state) => state);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const userType = useState(Users.CLEANER);
  const [isMobile, setIsMobile] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLIENT });
  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex w-full flex-col items-center gap-4 sm:flex-row sm:items-center sm:justify-start">
      {!isAuthorized && userType ? (
        <Link to={bookNowHref} className="flex w-full justify-center sm:w-auto">
          <Button
            size={Sizes.S}
            variant={ButtonVariants.OPACITY_PRIMARY}
            className="w-11/12 !text-yellow-400 sm:w-auto sm:!text-white-base"
          >
            Book Now
          </Button>
        </Link>
      ) : null}

      <div
        className={`relative flex w-full items-center gap-2 ${isAuthorized ? "sm:absolute sm:w-[105px]" : "sm:w-auto"}`}
        ref={dropdownRef}
      >
        <Link
          to={cleanWithUsHref}
          className={`flex w-full justify-center sm:w-auto ${isAuthorized && isMobile ? "hidden" : ""}`}
        >
          <Button
            size={Sizes.S}
            variant={!isAuthorized ? ButtonVariants.OPACITY_PRIMARY : undefined}
            className={`${isAuthorized ? "pr-2" : ""} w-11/12 !text-yellow-400 sm:w-auto sm:!text-white-base`}
          >
            {isAuthorized ? (
              <Avatar avatarUrl={user?.avatar} />
            ) : (
              <span>Clean With Us</span>
            )}
          </Button>
        </Link>
        {isAuthorized && (
          <button
            onClick={handleClick}
            className="flex items-center focus:outline-none"
            aria-expanded={isOpen}
            aria-haspopup="true"
          >
            <ArrowDown
              className={`transform transition-transform duration-200 ${isOpen ? "rotate-180" : ""}`}
            />
          </button>
        )}

        {/* <details className="dropdown">
  <summary className="btn m-1">open or close</summary>
  <ul className="menu dropdown-content bg-base-100 rounded-box z-[1] w-52 p-2 shadow">
    <li><a>Item 1</a></li>
    <li><a>Item 2</a></li>
  </ul>
</details> */}
        <div
          className={`absolute right-0 top-12 w-[200px] ${isOpen ? "block" : "hidden"} z-[9999]`}
          style={{
            backgroundColor: "white",
            boxShadow: "0 2px 5px rgba(0,0,0,0.2)",
            border: "1px solid #e5e7eb",
            borderRadius: "0.5rem",
            // zIndex: "99999",
          }}
        >
          <div className="flex flex-col py-2">
            {NAVIGATION_LINKS.map((link) => (
              <Link
                key={link.id}
                to={link.href}
                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
                onClick={() => setIsOpen(false)}
              >
                {link.label}
              </Link>
            ))}
            <div className="my-2 h-px bg-gray-200" />
            <Link
              to={PATHNAMES.ACCOUNT}
              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-50"
              onClick={() => setIsOpen(false)}
            >
              Account Settings
            </Link>
            <button
              className="px-4 py-2 text-left text-sm text-red-600 hover:bg-gray-50"
              onClick={() => {
                // Add your logout logic here
                setIsOpen(false);
              }}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionsButtons;
