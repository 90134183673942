import { instance } from "../api-client";

interface ICreateSubscription {
  plan_id: number;
}

interface ICreateSubscriptionResponse {
  approval_url: string;
}

export const SubscriptionServices = {
  async createSubscription(
    params: ICreateSubscription
  ): Promise<ICreateSubscriptionResponse> {
    const { data } = await instance.post<ICreateSubscriptionResponse>(
      "api/subscriptions",
      params
    );

    return data;
  },
};
