import React, { FC, useState } from "react";
import cn from "classnames";
import { ReactComponent as ArrowBottomIcon } from "src/assets/icons/arrow-bottom-yellow.svg";
import { IOptionSelect, SetOptionSelect } from "src/@types/form";
import { Dropdown } from "../Dropdown";
import { SortOption } from "./SortOption";

export interface ISortingDropdownListProps {
  className?: string;
  options: IOptionSelect[];
  activeOption: IOptionSelect | any;
  placeholder?: string;
  setOption: SetOptionSelect | any;
  headLabel?: string;
  labelKey?: string;
  valueKey?: string;
  disabled?: boolean;
}

export const SortingDropdownList: FC<ISortingDropdownListProps> = ({
  className,
  options,
  activeOption,
  placeholder,
  setOption,
  headLabel,
  labelKey = "label",
  valueKey = "value",
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const getLabel = (option: IOptionSelect) =>
    option[labelKey as keyof IOptionSelect] as string;
  const getValue = (option: IOptionSelect) =>
    option[valueKey as keyof IOptionSelect];

  const headDropdownValue = (
    <div
      className={cn(
        "flex w-full items-center justify-between gap-2 rounded-10 border border-gray-base bg-white-base px-4 py-3",
        { "cursor-default !border-[#D0D5DD33] !bg-[#D0D5DD33]": disabled },
        { "rounded-b-none border-yellow-base": isOpen }
      )}
    >
      {activeOption && getLabel(activeOption) ? (
        <span>{getLabel(activeOption)}</span>
      ) : (
        placeholder && (
          <span className="text-sm font-normal leading-snug tracking-tight text-black-third opacity-60">
            {placeholder}
          </span>
        )
      )}

      {!disabled && (
        <ArrowBottomIcon
          className={cn("transition-all duration-200", {
            "rotate-180": isOpen,
          })}
        />
      )}
    </div>
  );

  const filteredOptions = activeOption
    ? options.filter((option) => getValue(option) !== getValue(activeOption))
    : options;

  const setActiveOption = (option: IOptionSelect) => {
    setOption(option);
    setIsOpen(false);
  };

  return (
    <Dropdown
      className={className}
      placeholder={headDropdownValue}
      head={headLabel}
      bodyClassName="bg-white-base w-full top-13 z-10 rounded-b-10 "
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      disabled={disabled}
    >
      <ul className="max-h-[300px] overflow-auto">
        {filteredOptions.map((option) => (
          <SortOption
            key={getValue(option)}
            option={option}
            setOption={setActiveOption}
            labelKey={labelKey}
            valueKey={valueKey}
          />
        ))}
      </ul>
    </Dropdown>
  );
};
