export const addQueryParams = (
  url: string,
  params: Record<string, string | number | boolean>
) => {
  const urlObj = new URL(url, window.location.origin); // Вказує базовий URL для коректного парсингу відносних шляхів

  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.set(key, String(value)); // Перетворюємо значення у рядок
  });

  return urlObj.toString();
};
