import React, { FC } from "react";
import ForgetPasswordBlock from "src/page-components/forget-password";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const ForgetPassword: FC = () => {
  return (
    <PageWrapper isShownHeader={false} isShownFooter={false}>
      <ForgetPasswordBlock />
    </PageWrapper>
  );
};

export default ForgetPassword;
