import React from "react";
import cn from "classnames";

interface IPageBannerProps {
  className?: string;
  title: string;
  titleClassName?: string;
  description?: string;
  children?: React.ReactNode;
}

const PageBanner = ({
  className,
  title,
  titleClassName,
  description,
  children,
}: IPageBannerProps) => {
  return (
    <section className={cn("w-full py-[99px]", className)}>
      <div className="container flex flex-col gap-6">
        <div className="flex flex-col gap-[14px]">
          <h2
            className={cn(
              "leadeing-[48px] text-center text-[40px] font-semibold !text-white-base lg:text-start lg:text-[64px] lg:leading-[84px]",
              titleClassName
            )}
          >
            {title}
          </h2>
          {description && (
            <div className="hidden w-[471px] text-base font-normal leading-7 !text-white-base lg:block">
              {description}
            </div>
          )}
        </div>

        {children}
      </div>
    </section>
  );
};

export default PageBanner;
