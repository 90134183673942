import CallToAction from "src/page-components/about-us/CallToAction";
import Welcome from "src/page-components/about-us/Welcome";
import WhyChooseUs from "src/page-components/about-us/WhyChooseUs";
import PageBanner from "src/page-components/PageBanner";
import { PageWrapper } from "src/components/Layouts/PageWrapper";

const AboutUs = () => {
  return (
    <PageWrapper mainClassName="flex flex-col gap-[100px] items-center pb-[100px]">
      <PageBanner
        className="bg-about-us-banner w-full bg-cover bg-center bg-no-repeat"
        title="About Us"
        description="Lorem ipsum dolor sit amet consectetur. Vel libero ipsum pharetra nisl
          aliquet fermentum mi elementum sagittis."
      />

      <Welcome />

      <WhyChooseUs />

      <CallToAction />
    </PageWrapper>
  );
};

export default AboutUs;
