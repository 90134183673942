import React, { ReactNode } from "react";
import cn from "classnames";
import { IAvailableCleaner } from "src/services/client-service/types";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { ReactComponent as BagIcon } from "src/assets/icons/bag.svg";
import { ReactComponent as HouseIcon } from "src/assets/icons/house.svg";
import { ReactComponent as StarThirdIcon } from "src/assets/icons/star-third.svg";
import CleanerItemImage from "src/assets/images/cleaner-item.png";
import { Sizes } from "src/@types/sizes";

const InfoItem = ({ icon, text }: { icon: ReactNode; text: string }) => {
  return (
    <div className="flex items-center gap-2">
      {icon}
      <div className="text-base font-medium text-[#929395]">{text}</div>
    </div>
  );
};

interface ICleanerItemProps extends IAvailableCleaner {
  isSelected?: boolean;
  onSelectCleaner?: () => void;
  onClickDetail?: () => void;
}

const CleanerItem = ({
  id,
  onClickDetail,
  onSelectCleaner,
  bio,
  rating,
  name,
  cleanings_completed,
  experience_years,
  isSelected,
}: ICleanerItemProps) => {
  const onClickSelect = (e) => {
    e.stopPropagation();
    onSelectCleaner();
  };

  return (
    <button
      onClick={onClickDetail}
      className={cn(
        "flex w-full flex-col items-center gap-5 rounded-[16px] border-[3px] border-white-base bg-white-base",
        isSelected && "border-[3px] !border-yellow-base"
      )}
    >
      <div className="w-full p-2 pb-0">
        <div className="relative flex w-full justify-center rounded-[8px] bg-purple-base">
          <img src={CleanerItemImage} alt="cleaner" />

          <div className="absolute right-2 top-2 rounded-[30px] bg-white-base px-[10px] py-2 text-[10px] font-bold text-green-base">
            OPEN TO WORK
          </div>
        </div>
      </div>

      <div>
        <div className="flex flex-col gap-4 px-4">
          <div className="flex flex-col gap-2">
            <h4 className="text-2xl font-semibold text-gray-strong">{name}</h4>
            <p className="text-base font-light leading-normal text-black-third">
              {bio}
            </p>
          </div>

          <div className="flex items-center justify-between gap-3">
            <InfoItem
              text={`${cleanings_completed} done`}
              icon={<HouseIcon />}
            />
            <InfoItem text={`${experience_years} years`} icon={<BagIcon />} />
            <InfoItem text={`${rating} star`} icon={<StarThirdIcon />} />
          </div>
        </div>

        <div className="mt-6 flex w-full items-center justify-center border-t border-[#ebebeb] py-3">
          <Button
            onClick={onClickSelect}
            size={Sizes.S}
            variant={
              isSelected
                ? ButtonVariants.PRIMARY
                : ButtonVariants.OPACITY_PRIMARY
            }
          >
            {isSelected ? "Cleaner Selected" : "Select a Cleaner"}
          </Button>
        </div>
      </div>
    </button>
  );
};

export default CleanerItem;
