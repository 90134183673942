import { FC } from "react";
import cn from "classnames";
import { Field } from "formik";
import { IRenderFormField } from "src/@types/form";

interface Props
  extends Pick<
    IRenderFormField,
    "className" | "fieldClassName" | "labelClassName" | "variant" | "onChange"
  > {
  fields: IRenderFormField[];
  disabled?: boolean;
}

export const RenderFormFields: FC<Props> = ({
  fields = [],
  className,
  fieldClassName,
  labelClassName,
  disabled,
  onChange,
  ...props
}) => (
  <>
    {fields.map(({ component: FieldComponent, ...item }) => (
      <Field key={item.name} {...item} {...props}>
        {({ field, form }: any) => (
          <FieldComponent
            key={field.name}
            disabled={field.disabled ? field.disabled : disabled}
            className={cn(className, field.className)}
            fieldClassName={cn(fieldClassName, field.fieldClassName)}
            labelClassName={cn(labelClassName, field.labelClassName)}
            onChange={onChange}
            name={field.name}
            label={field.label}
            {...field}
            {...item}
            form={form}
            {...props}
          />
        )}
      </Field>
    ))}
  </>
);
