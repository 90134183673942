import React, { forwardRef } from "react";
import ReactSelect, {
  Props as ReactSelectProps,
  SingleValue,
} from "react-select";

type Option = {
  value: string;
  label: string;
};

type SelectProps = {
  options: Option[] | any;
  isLoading?: boolean;
  getOptionLabel?: (option: Option | any) => string;
  getOptionValue?: (option: Option | any) => string | number;
  value?: SingleValue<Option> | any;
  onChange?: (option: SingleValue<Option>) => void;
  placeholder?: string;
  error?: string;
  headLabel?: string;
  isMulti?: boolean;
  isDisabled?: boolean;
};

const Select = forwardRef<any, SelectProps>(
  (
    {
      options,
      isLoading = false,
      getOptionLabel,
      getOptionValue,
      value,
      onChange,
      isDisabled,
      headLabel,
      placeholder = "Select...",
      error,
      ...props
    },
    ref
  ) => {
    const isError = !!error;

    return (
      <div className="">
        <div className="text-lg font-normal capitalize leading-7 tracking-tight text-black-third">
          {headLabel}
        </div>
        <ReactSelect
          options={options}
          isLoading={isLoading}
          isDisabled={isDisabled}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue as any}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          ref={ref}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "white",
              primary: "white",
            },
          })}
          styles={{
            control: (baseStyles, { isDisabled }) => ({
              ...baseStyles,
              borderRadius: "10px",
              border: isDisabled ? "1px solid #d3d3d3" : "1px solid #919494",
              backgroundColor: isDisabled ? "#f5f5f5" : "transparent",
              padding: "4px 16px",
              opacity: isDisabled ? 0.6 : 1,
              cursor: isDisabled ? "not-allowed" : "default",
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              fontSize: "0.875rem",
              fontWeight: "400",
              lineHeight: "1.25rem",
              letterSpacing: "-0.015em",
              color: "#919494",
              opacity: "0.6",
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: "white",
            }),
            multiValue: (baseStyles) => ({
              ...baseStyles,
              backgroundColor: "transparent",
            }),
          }}
          components={{
            IndicatorSeparator: () => null,
          }}
          {...props}
        />

        {isError && <p className="text-sm text-red-500">{error}</p>}
      </div>
    );
  }
);

Select.displayName = "Select";

export default Select;
