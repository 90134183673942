import cn from "classnames";
import { Sizes } from "src/@types/sizes";
import { ButtonVariants } from "./types";

const DEFAULT_BUTTON_CLASSNAME = "rounded-30 font-bold";

export const BUTTON_STYLE_VARIANTS = {
  [ButtonVariants.PRIMARY]: cn(
    "bg-yellow-base text-white-base border border-yellow-base",
    DEFAULT_BUTTON_CLASSNAME
  ),
  [ButtonVariants.OPACITY_PRIMARY]: cn(
    "bg-transparent text-yellow-base border border-yellow-base",
    DEFAULT_BUTTON_CLASSNAME
  ),
  [ButtonVariants.CANCEL]: cn(
    "bg-gray-ligth text-blue-dark",
    DEFAULT_BUTTON_CLASSNAME
  ),
  [ButtonVariants.SECONDARY]: "",
};

export const BUTTON_STYLE_SIZE = {
  [Sizes.S]: "text-sm py-[14px] px-6",
  [Sizes.M]: "text-base py-5 px-8",
};
