import React, { FC, useMemo } from "react";
import cn from "classnames";
import { FormField } from "src/components/FormField";
import { TEXT_INPUT_STYLE_VARIANTS } from "src/components/FormField/constants";
import { FormFieldVariants } from "src/components/FormField/types";

export interface ITextInputProps {
  id?: string;
  name: string;
  label?: string;
  type?: string;
  isTextArea?: boolean;
  mask?: (string | RegExp)[];
  placeholder?: string;
  className?: string;
  fieldClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  variant?: FormFieldVariants;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  value: string | number;
  isShownError?: boolean;
  error?: string;
  min?: number;
}

export const TextInput: FC<ITextInputProps> = ({
  className,
  fieldClassName,
  labelClassName,
  id,
  label,
  variant = FormFieldVariants.PRIMARY,
  placeholder,
  isTextArea,
  disabled,
  onChange,
  value,
  isShownError,
  error,
  min,
  ...props
}) => {
  const fieldId = id || props.name;

  const combinedClassNames = cn(
    "w-full outline-none disabled:bg-[#D0D5DD33] disabled:cursor-default disabled:border-[#D0D5DD33]",
    TEXT_INPUT_STYLE_VARIANTS[variant],
    className,
    { "resize-y": isTextArea }
  );

  const InputComponent = useMemo(() => {
    if (isTextArea) {
      return "textarea";
    } else {
      return "input";
    }
  }, [isTextArea]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(e);
  };

  return (
    <FormField
      className={fieldClassName}
      labelClassName={labelClassName}
      variant={variant}
      label={label}
      labelFor={fieldId}
      isShownError={isShownError}
      error={error}
    >
      <InputComponent
        id={fieldId}
        className={combinedClassNames}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onChange={handleChange}
        min={min}
        {...props}
      />
    </FormField>
  );
};
