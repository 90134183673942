import { FC } from "react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  newEmailAddress: string;
  setIsNextModalOpen: (value: boolean) => void;
}

export const ConfirmationModal: FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  newEmailAddress,
  setIsNextModalOpen,
}) => {
  const handleSendClick = () => {
    setIsModalOpen(false);
    setIsNextModalOpen(true);
  };

  return (
    <ModalWindow
      isOpen={isModalOpen}
      onClose={() => setIsModalOpen(false)}
      isShownCloseButton={false}
      isActiveCloseClickOutside={true}
      className="flex justify-center"
    >
      <ModalPrimaryTemplate
        className="w-[375px] lg:w-[700px]"
        onClose={() => setIsModalOpen(false)}
      >
        <h3 className="text-center text-[28px]">Email Confirmation Sent</h3>
        <p className="text-center text-sm">
          We’ve sent a confirmation email to(
          <span className="whitespace-nowrap text-yellow-base">
            ${newEmailAddress}
          </span>
          ) .<br />
          Please check your inbox and click the confirmation link to complete
          the update.
        </p>
        <div className="mt-5 flex w-full items-center justify-center gap-5">
          <Button
            variant={ButtonVariants.PRIMARY}
            className="px-6 py-2"
            onClick={handleSendClick}
          >
            Check Email
          </Button>
          <Button variant={ButtonVariants.SECONDARY}>Resend Email</Button>
        </div>
      </ModalPrimaryTemplate>
    </ModalWindow>
  );
};
