import React, { FC, useEffect, useState } from "react";
import cn from "classnames";
import useSubscriptionStore from "src/store/subscription-store";
import { ReactComponent as ArrowIcon } from "src/assets/icons/arrow-down.svg";
import { ReactComponent as ArrowIcon2 } from "src/assets/icons/arrow-right-circle-black.svg";
import { ReactComponent as InfoIcon } from "src/assets/icons/info.svg";
import { Button } from "../Button";

const IMPORTANT_ITEM_CLASS = "relative mt-2 before:absolute before:-left-4";

interface SubscriptionPlan {
  id: number;
  name: string;
  description: string;
  price_usd: number;
  interval_unit: string;
}

interface UserSubscription {
  id: number;
  start_date: string | null;
  end_date: string | null;
  status: string;
  plan: SubscriptionPlan;
}

interface SubscriptionSectionProps {
  // userSubscriptions: UserSubscription[];
  userSubscriptions: any;
}

export const CurrentSubscription: FC<SubscriptionSectionProps> = ({
  userSubscriptions,
}) => {
  const [isVisibleImportant, setIsVisibleImportant] = useState(false);

  const activeSubscription = Array.isArray(userSubscriptions)
    ? userSubscriptions.find((sub) => sub.status === "active")
    : null;

  console.log("TESSSST: ", userSubscriptions);

  const handleToggleImportant = () => {
    setIsVisibleImportant(!isVisibleImportant);
  };

  return (
    <section>
      <div className="container py-20">
        <div className="flex flex-col items-center gap-6">
          <h2 className="text-5xl font-bold">My Subscription Plan</h2>

          {userSubscriptions ? (
            <div className="min-h-[300px] w-full max-w-[1000px] rounded-lg bg-gray-100 p-6 shadow-md">
              <h3 className="text-lg font-semibold">
                {userSubscriptions.plan.name}
              </h3>
              <p className="text-sm text-gray-600">
                {userSubscriptions.plan.description}
              </p>
              <ul className="mt-4 space-y-2">
                <li>
                  <div className="flex max-w-96 items-center gap-3">
                    <ArrowIcon2 className="mt-1 min-w-6" />

                    <p className="mt-2">
                      {" "}
                      Start Date:{" "}
                      {new Date(
                        userSubscriptions.start_date!
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="flex max-w-96 gap-3">
                    <ArrowIcon2 className="mt-1 min-w-6" />

                    <p className="mt-2">
                      {" "}
                      End Date:{" "}
                      {new Date(
                        userSubscriptions.end_date!
                      ).toLocaleDateString()}
                    </p>
                  </div>
                </li>
                <li>
                  <div className="flex max-w-96 gap-3">
                    <ArrowIcon2 className="mt-1 min-w-6" />

                    <p className="mt-2">
                      Price: ${userSubscriptions.plan.price_usd} /{" "}
                      {userSubscriptions.plan.interval_unit.toLowerCase()}
                    </p>
                  </div>
                </li>
              </ul>
              <hr className="mb-5 mt-7.5" />

              <Button
                className="w-full !justify-between"
                onClick={handleToggleImportant}
              >
                <div className="flex gap-3">
                  <InfoIcon />
                  <span>Important</span>
                </div>

                <ArrowIcon />
              </Button>
              {isVisibleImportant && (
                <div className="ml-5 mt-3.5">
                  <p
                    className={cn(
                      "before:content-['1.']",
                      IMPORTANT_ITEM_CLASS
                    )}
                  >
                    The platform does not charge a commission on each
                    transaction.
                  </p>
                  <p
                    className={cn(
                      "before:content-['2.']",
                      IMPORTANT_ITEM_CLASS
                    )}
                  >
                    Cleaners cannot advertise their websites.
                  </p>
                  <p
                    className={cn(
                      "before:content-['3.']",
                      IMPORTANT_ITEM_CLASS
                    )}
                  >
                    The agreement prohibits clients and cleaners from working
                    directly outside the platform - violations result in
                    blocking.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full max-w-lg rounded-lg p-6 text-center">
              <p className="text-2xl">
                You don’t have a subscription yet. Subscribe below, and it will
                appear here.
              </p>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
