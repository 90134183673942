import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import bookingServices from "src/services/booking-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import useBookCleaningStore from "src/store/book-cleaning-store";
import CleanersList from "src/page-components/available-cleaners/CleanersList";
import NotFoundCleaners from "src/page-components/available-cleaners/NotFoundCleaners";
import PageBanner from "src/page-components/PageBanner";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { PageWrapper } from "src/components/Layouts/PageWrapper";
import { Search } from "src/components/Search";
import { NotificationService } from "src/helpers/notifications";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";

const AvailableCleanersMessage = ({ text }: { text: string }) => {
  return (
    <div className="flex flex-col gap-32">
      <div className="flex flex-col gap-7">
        <h2 className="max-w-[936px] text-[50px] font-semibold uppercase tracking-[10px] !text-black-third">
          {text}
        </h2>
      </div>
    </div>
  );
};

const AvailableCleaners: FC = () => {
  const navigate = useNavigate();
  const {
    onPreviousStep,
    zip_code,
    time,
    date,
    team_size,
    cleaning_type,
    updateField,
    onSetStep,
  } = useBookCleaningStore((state) => state);
  const [isDirty, setIsDirty] = useState(false);

  const {
    data: availableCleanersData,
    isFetching,
    isFetched,
    refetch,
  } = useQuery<IAvailableCleaner[]>({
    queryKey: ["getAvailableCleaners"],
    queryFn: async () =>
      bookingServices.getAvailableCleaners({
        zip_code,
        cleaning_type_id: cleaning_type.id,
        date: `${date} ${time}`,
        team_size: team_size.value,
      }),
    enabled: false,
  });

  const handleZipCodeChange = (value: string) => {
    setIsDirty(true);
    updateField("zip_code", value);
  };

  const onBack = () => {
    navigate(PATHNAMES.BOOK_CLEANING);
    onPreviousStep();
  };

  const checkIfAllFieldsAreFilled = () => {
    debugger;
    const requiredFields = [cleaning_type.id, date, time, team_size.value];

    return requiredFields.every((field) => field && field !== 0);
  };

  useEffect(() => {
    //check if all fields are filled
    const isMissing = checkIfAllFieldsAreFilled();

    if (!isMissing) {
      navigate(PATHNAMES.BOOK_CLEANING);
      onSetStep(0);
      NotificationService.error("Please fill all fields");
    }

    //refetch data if refresh
    if (zip_code && checkIfAllFieldsAreFilled()) {
      refetch();
    }
  }, []);

  return (
    <PageWrapper className="">
      <PageBanner
        className="w-full bg-cleaner-banner bg-cover bg-top bg-no-repeat py-[78px]"
        title="Enter Your Postal Code To Get Started"
        titleClassName="max-w-[682px]"
      >
        <div className="flex flex-col-reverse items-center gap-6 lg:max-w-[650px] lg:flex-row">
          <Button
            onClick={onBack}
            size={Sizes.S}
            variant={ButtonVariants.OPACITY_PRIMARY}
            className="w-full lg:w-auto"
          >
            Previous Step
          </Button>
          <Search
            onSearch={() => setIsDirty(false)}
            className="flex-1"
            value={zip_code}
            onChange={(e) => handleZipCodeChange(e.target.value)}
          />
        </div>
      </PageBanner>

      <section className="bg-blue-ligther py-[100px]">
        <div className="container">
          {/* <CleanersList cleaners={availableCleanersData} /> */}

          {isFetching ? (
            <div>Loading...</div>
          ) : isDirty ? (
            <AvailableCleanersMessage text="Please confirm your search to view available cleaners." />
          ) : isFetched && availableCleanersData?.length === 0 ? (
            <NotFoundCleaners />
          ) : isFetched && zip_code && availableCleanersData?.length !== 0 ? (
            <CleanersList cleaners={availableCleanersData} />
          ) : (
            <AvailableCleanersMessage text="Please confirm your search to view available cleaners." />
          )}
        </div>
      </section>
    </PageWrapper>
  );
};

export default AvailableCleaners;
