import { instance } from "./api-client";

export interface IResendEmailVerificationBody {
  email: string;
}

export const authServices = {
  async resendEmailVerevi(body: IResendEmailVerificationBody) {
    const { data } = await instance.post("/api/auth/resend-verification", body);

    return data;
  },
};

export default authServices;
