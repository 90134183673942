import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import bookingServices from "src/services/booking-service";
import { ICreateCleanerSchedules } from "src/services/client-service";
import { IAvailableCleaner } from "src/services/client-service/types";
import useBookCleaningStore from "src/store/book-cleaning-store";
import useClientStore from "src/store/client-store";
import CleanerDetails from "src/page-components/available-cleaners/CleanerDetails";
import CleanerItem from "src/page-components/available-cleaners/CleanerItem";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import SearchField from "src/components/FormField/SearchField";
import { ModalWindow } from "src/components/ModalWindow";
import ModalPrimaryTemplate from "src/components/ModalWindow/templates/ModalPrimaryTemplate";
import { useModal } from "src/hooks/useModal";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as FilterIcon } from "src/assets/icons/filter.svg";
import { ReactComponent as SortIcon } from "src/assets/icons/sort.svg";
import { Sizes } from "src/@types/sizes";

interface ICleanersListProps {
  cleaners: IAvailableCleaner[];
}

const CleanersList = ({ cleaners }: ICleanersListProps) => {
  const { selected_cleaner, updateField } = useBookCleaningStore();
  const [detailsCleaner, setDetailsCleaner] = useState<IAvailableCleaner>(null);

  const { isOpenModal, openModal, closeModal } = useModal(false);
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const navigate = useNavigate();

  //if user is not authorized, redirect to subscription page
  // const onClickCleanerItem = () => {
  //   if (isAuthorized) {
  //     openModal();
  //   } else {
  //     navigate(PATHNAMES.SUBSCRIPTION);
  //   }
  // };

  const handleSelectCleaner = (cleaner: IAvailableCleaner) => {
    if (selected_cleaner?.id === cleaner.id) {
      updateField("selected_cleaner", null);
      return;
    }

    updateField("selected_cleaner", cleaner);
  };

  const handleDetailsCleaner = (cleaner: IAvailableCleaner) => {
    setDetailsCleaner(cleaner);
    openModal();
  };

  return (
    <>
      <div className="container flex flex-col gap-10">
        <div className="flex w-full flex-col justify-between lg:flex-row lg:items-center">
          <h2 className="text-start text-[28px] font-semibold leading-[64px] text-gray-strong lg:text-center lg:text-[46px]">
            Select the cleaner
          </h2>

          <div className="flex w-full flex-col-reverse items-center gap-12 lg:flex-row">
            <div className="flex w-full items-center gap-8">
              <div className="flex w-full items-center gap-2">
                <FilterIcon /> Filter
              </div>

              <div className="flex items-center gap-2">
                <SortIcon /> Sort
              </div>
            </div>

            <div className="flex w-full items-center gap-2">
              <SearchField placeholder="Search..." />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10 lg:grid-cols-3">
          {cleaners?.map((item) => (
            <CleanerItem
              key={item.id}
              isSelected={selected_cleaner?.id === item.id}
              onSelectCleaner={() => handleSelectCleaner(item)}
              onClickDetail={() => handleDetailsCleaner(item)}
              {...item}
            />
          ))}
        </div>

        <div className="flex flex-col items-center gap-6">
          <Link to={PATHNAMES.BOOKING_DETAILS}>
            <Button
              isDisabled={selected_cleaner === null}
              size={Sizes.S}
              variant={ButtonVariants.PRIMARY}
            >
              Finish
            </Button>
          </Link>

          <Link className="text-yellow-base underline" to={PATHNAMES.HOME}>
            How the rate is calculated?
          </Link>
        </div>
      </div>

      <ModalWindow
        isShownCloseButton={false}
        onClose={closeModal}
        isOpen={isOpenModal}
      >
        <ModalPrimaryTemplate onClose={closeModal}>
          <div className="min-w-[800px]">
            <CleanerDetails
              isSelected={selected_cleaner?.id === detailsCleaner?.id}
              onSelect={handleSelectCleaner}
              cleaner={detailsCleaner}
            />
          </div>
        </ModalPrimaryTemplate>
      </ModalWindow>
    </>
  );
};

export default CleanersList;
