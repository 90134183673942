import React, { FC } from "react";
import { Link } from "react-router-dom";
import { ICleanerCard } from "./types";

export const CleanerCard: FC<ICleanerCard> = ({
  image,
  name,
  description,
  url,
}) => (
  <article>
    <div className="h-64 w-full lg:w-64">
      <img
        className="h-full w-full rounded-t-xl object-cover"
        src={image}
        alt={name}
      />
    </div>

    <div className="bg-white-base px-8 py-4">
      <span className="text-xl font-semibold text-black-medium">{name}</span>
      <p>{description}</p>
    </div>

    <Link
      className="flex justify-center rounded-b-xl border-t border-blue-ligther bg-white-base py-3.5 font-bold text-yellow-base transition-all duration-200 hover:bg-orange-base hover:text-white-base"
      to={url}
    >
      Communicate
    </Link>
  </article>
);
