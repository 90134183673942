import React from "react";

interface IValueItemProps {
  number: number;
  title: string;
  description: string;
}

const ValueItem = ({ number, title, description }: IValueItemProps) => {
  return (
    <div className="flex flex-col items-start gap-4">
      <div className="bg-gray-strong inline-block rounded-full px-[9px] py-[6px] font-bold text-white-base">
        <span>0{number}</span>
      </div>

      <div className="flex flex-col gap-[10px]">
        <h5 className="text-gray-strong text-lg font-medium capitalize leading-normal">
          {title}
        </h5>
        <p className="text-gray-strong text-sm font-normal leading-tight">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ValueItem;
