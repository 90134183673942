import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import clientServices, {
  ICreateCleanerSchedules,
} from "src/services/client-service";
import { Checkbox } from "src/ui/Checkbox";
import TimeInput from "src/ui/TimeInput";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { convertToISOTimeWithoutDate } from "src/utils/dataAndTime";
import { ReactComponent as MinusIcon } from "src/assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "src/assets/icons/plus.svg";
import { Sizes } from "src/@types/sizes";
import WeekdayItem from "./WeekdayItem";

const weekData = [
  { id: 1, title: "Monday" },
  { id: 2, title: "Tuesday" },
  { id: 3, title: "Wednesday" },
  { id: 4, title: "Thursday" },
  { id: 5, title: "Friday" },
  { id: 6, title: "Saturday" },
  { id: 7, title: "Sunday" },
];

const ScheduleManager = () => {
  const queryClient = useQueryClient();

  const { data: schedulesData } = useQuery({
    queryKey: ["cleanerSchedules"],
    queryFn: () =>
      clientServices.getCleanerSchedules().then((res) => sortWeekdays(res)),
  });

  const { mutate: createSchedule } = useMutation({
    mutationFn: (data: ICreateCleanerSchedules) =>
      clientServices.createCleanerSchedules(data),
  });

  const { mutate: updateSchedule } = useMutation({
    mutationFn: ({
      weekday,
      data,
    }: {
      weekday: number;
      data: ICreateCleanerSchedules;
    }) => clientServices.updateCleanerSchedules(weekday, data),
  });

  const { mutate: deleteSchedule } = useMutation({
    mutationFn: ({ weekday }: { weekday: number }) =>
      clientServices.deleteCleanerSchedules(weekday),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["cleanerSchedules"] });
    },
  });

  const [workingDays, setWorkingDays] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);
  const [showScheduler, setShowScheduler] = useState(true);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const toggleScheduler = () => {
    setShowScheduler((prev) => !prev);
  };

  const sortWeekdays = (weekdays: ICreateCleanerSchedules[]) => {
    return weekdays.sort((a, b) => a.weekday - b.weekday);
  };

  useEffect(() => {
    if (schedulesData) {
      const sortedWeekdays = sortWeekdays(schedulesData);
      setWorkingDays(sortedWeekdays);
    }
  }, [schedulesData]);

  const toggleDaySelection = (weekday) => {
    setSelectedDays((prev) =>
      prev.includes(weekday)
        ? prev.filter((day) => day !== weekday)
        : [...prev, weekday]
    );
  };

  const applyToSelectedDays = () => {
    if (!startTime || !endTime) {
      alert("Please fill in both start and end time.");
      return;
    }

    const updatedDays = selectedDays.map((weekday) => ({
      weekday,
      start_time: startTime,
      end_time: endTime,
    }));

    const newWorkingDays = [...workingDays];

    updatedDays.forEach((updatedDay) => {
      const existingIndex = newWorkingDays.findIndex(
        (day) => day.weekday === updatedDay.weekday
      );

      if (existingIndex > -1) {
        newWorkingDays[existingIndex] = updatedDay;
        updateSchedule({ weekday: updatedDay.weekday, data: updatedDay });
      } else {
        newWorkingDays.push(updatedDay);
        createSchedule(updatedDay);
      }
    });

    setWorkingDays(sortWeekdays(newWorkingDays));
    setSelectedDays([]);
    setStartTime("");
    setEndTime("");
  };

  const deleteSchedulesWeekday = (weekday) => {
    deleteSchedule({ weekday });
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <button
          className="text-sm font-bold text-gray-strong"
          onClick={toggleScheduler}
        >
          {showScheduler ? (
            <div className="flex items-center gap-2">
              <MinusIcon /> Hide Scheduler
            </div>
          ) : (
            <div className="flex items-center gap-2">
              <PlusIcon /> Add Time
            </div>
          )}
        </button>

        {showScheduler && (
          <div className="flex flex-col gap-[10px]">
            <h4 className="text-base font-normal capitalize leading-normal tracking-tight text-gray-strong">
              Select days for update time:
            </h4>
            <div className="flex flex-col gap-[10px]">
              {weekData.map((day) => (
                <Checkbox
                  key={day.id}
                  label={day.title}
                  onChange={() => toggleDaySelection(day.id)}
                  name={day.title}
                  checked={selectedDays.includes(day.id)}
                />
              ))}
            </div>

            <div className="flex flex-col items-start gap-5">
              <div className="flex items-center gap-2">
                <TimeInput
                  className=""
                  id="start-time"
                  label="From"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
                <div className="translate-y-[60%] text-black-third">-</div>
                <TimeInput
                  id="to-time"
                  label="To"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </div>

              <Button
                className="w-full"
                variant={ButtonVariants.PRIMARY}
                size={Sizes.S}
                onClick={applyToSelectedDays}
              >
                Save
              </Button>
            </div>
          </div>
        )}
      </div>

      <div>
        <h4>Your schedule:</h4>
        <ul>
          {workingDays.map((day) => (
            <WeekdayItem
              onDelete={() => deleteSchedulesWeekday(day.weekday)}
              key={day.weekday}
              title={weekData.find((w) => w.id === day.weekday)?.title}
              start_time={day.start_time}
              end_time={day.end_time}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ScheduleManager;
