import { useState } from "react";
import cn from "classnames";
import { ReactComponent as WarningIcon } from "src/assets/icons/warning-icon.svg";

const STATUS_STYLES = {
  pending: {
    content: "Pending",
    color: "bg-[#F4B10B] border-[#F4B10B]",
  },
  canceled: {
    content: "Cancelled",
    color: "bg-[#F40B0B] border-[#F40B0B]",
  },
  approved: {
    content: "Approved",
    color: "bg-[#17BF28] border-[#17BF28]",
  },
};

const BookingBadge = ({
  status,
}: {
  status: "pending" | "canceled" | "approved";
}) => {
  const [isHovered, setIsHovered] = useState(false);

  let badgeContent = "";
  let badgeColor = "";

  switch (status) {
    case "pending":
      badgeContent = "Pending";
      badgeColor = "bg-[#F4B10B] border-[#F4B10B]";
      break;
    case "canceled":
      badgeContent = "Cancelled";
      badgeColor = "bg-[#F40B0B] border-[#F40B0B]";
      break;
    case "approved":
      badgeContent = "Approved";
      badgeColor = "bg-[#17BF28] border-[#17BF28]";
      break;
    default:
      badgeContent = "Unknown status";
      badgeColor = "bg-gray-500";
  }

  const renderBadge = (statusKey: "pending" | "canceled" | "approved") => {
    const { content, color } = STATUS_STYLES[statusKey];
    return (
      <div
        className={cn(
          "text-white inline-flex items-center gap-2 rounded-full border bg-opacity-10 px-2 py-1 text-xs",
          color
        )}
      >
        <div className={cn("h-2 w-2 rounded-full", color)} />
        <div>{content}</div>
      </div>
    );
  };

  return (
    <div className="relative inline-block">
      {/* Badge */}
      <div
        className={cn(
          "text-white inline-flex cursor-pointer items-center gap-2 rounded-full border bg-opacity-10 px-2 py-1 text-xs",
          badgeColor
        )}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={cn("h-2 w-2 rounded-full", badgeColor)} />
        <div>{badgeContent}</div>
      </div>

      {/* Popup */}
      {isHovered && (
        <div className="bg-white absolute left-1/2 z-10 mt-2 w-[450px] -translate-x-1/2 transform rounded-lg border border-gray-300 bg-white-base p-4 shadow-lg">
          <div className="flex items-center gap-2">
            <WarningIcon width={20} height={20} />
            <p>Status:</p>
          </div>
          <p className="text-sm text-gray-600">
            Note: The status of your application will update automatically once
            the cleaner approves your request.
          </p>
          <div className="mt-5">
            <p className="font-medium">Possible statuses:</p>
            <ul className="mt-2 flex items-center gap-2">
              {Object.keys(STATUS_STYLES).map((key) => (
                <li key={key}>
                  {renderBadge(key as "pending" | "canceled" | "approved")}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookingBadge;
