import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import bookingServices from "src/services/booking-service";
import useBookCleaningStore from "src/store/book-cleaning-store";

const localizer = momentLocalizer(moment);

const BigCalendar = () => {
  const { team_size } = useBookCleaningStore();
  const [events, setEvents] = useState([]);
  const { data: cleaningTypes, isLoading } = useQuery({
    queryKey: ["cleaningTypes"],
    queryFn: bookingServices.getCleaningTypes,
  });

  useEffect(() => {
    const fakeData = [
      {
        cleaner_id: 1,
        date: "2024-12-17T10:00:00.000Z",
        cleaning_type_id: 2,
        minimum_hours: 2,
      },
      {
        cleaner_id: 2,
        date: "2024-12-17T12:00:00.000Z",
        cleaning_type_id: 1,
        minimum_hours: 3,
      },
      {
        cleaner_id: 3,
        date: "2024-12-18T14:00:00.000Z",
        cleaning_type_id: 3,
        minimum_hours: 2,
      },
      {
        cleaner_id: 4,
        date: "2024-12-19T09:00:00.000Z",
        cleaning_type_id: 2,
        minimum_hours: 5,
      },
      {
        cleaner_id: 5,
        date: "2024-12-20T16:00:00.000Z",
        cleaning_type_id: 1,
        minimum_hours: 3,
      },
    ];

    const formattedEvents = fakeData.map((event) => {
      const endTime =
        new Date(event.date).getTime() + 1000 * 60 * 60 * event.minimum_hours;

      return {
        title: `Cleaning Type ${event.cleaning_type_id}`,
        start: new Date(event.date),
        end: endTime,
      };
    });

    setEvents(formattedEvents);
  }, []);

  return (
    <div className="h-[834px]">
      <Calendar
        localizer={localizer}
        events={events}
        views={["month", "week"]}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
};

export default BigCalendar;
