import React, { FC, useState } from "react";
import { Button } from "src/components/Button";
import { CLEANING_STEP_ITEMS } from "./constants";
import { ControlledFlow } from "./ControlledFlow";
import { ProgressBar } from "./ProgressBar";

interface ICleaningStepFormProps {
  activeStep: number;
  onNextClick: () => void;
  onPrevClick: () => void;
}

export const CleaningStepForm = ({
  activeStep,
  onNextClick,
  onPrevClick,
}: ICleaningStepFormProps) => {
  return (
    <div className="mx-auto mt-12 max-w-175 rounded-30 bg-blue-ligther px-12 py-12">
      <ProgressBar currentStep={activeStep + 1} />
      <hr className="mb-16 mt-8" />

      <ControlledFlow activeStep={activeStep}>
        {CLEANING_STEP_ITEMS.map(
          ({ id, stepComponent: StepComponent, title, subtitle }) => (
            <StepComponent
              key={id}
              onNextClick={onNextClick}
              onPrevClick={onPrevClick}
              title={title}
              subtitle={subtitle}
            />
          )
        )}
      </ControlledFlow>
    </div>
  );
};
