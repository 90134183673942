import React, { FC } from "react";
import { useNavigate } from "react-router";
import useClientStore from "src/store/client-store";
import { Avatar } from "src/components/Avatar";
import { Button } from "src/components/Button";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as EditIcon } from "src/assets/icons/edit.svg";
import { Sizes } from "src/@types/sizes";

// Компонент для отображения кружочка статуса
const StatusIndicator: FC<{ status: string }> = ({ status }) => {
  const statusColors: Record<string, string> = {
    UNCOMPLETED_PROFILE: "bg-gray-400",
    UNSIGNED_AGREEMENT: "bg-yellow-400",
    ON_MODERATION: "bg-blue-400",
    FAILED_MODERATION: "bg-red-400",
    UNSUBSCRIBED: "bg-purple-400",
    PUBLISHED: "bg-green-400",
  };

  const colorClass = statusColors[status] || "bg-gray-400";

  return <div className={`h-3 w-3 rounded-full ${colorClass}`} />;
};

export const AccountProfileHeader: FC = () => {
  const navigate = useNavigate();
  const { user } = useClientStore((state) => state);
  const subscription = useClientStore((state) => state.subscription);

  // Пример: определение статуса пользователя
  const profileStatus = user?.profile_status || "UNCOMPLETED_PROFILE"; // Используйте реальное поле статуса

  console.log("Profile Status:", profileStatus);

  return (
    <div className="mt-10 flex flex-row items-center justify-start gap-3 lg:flex-col">
      <div className="relative">
        <Avatar sizeVariant={Sizes.M} avatarUrl={user?.avatar} />
        <div className="absolute bottom-2 right-0">
          <StatusIndicator status={profileStatus} />
        </div>
      </div>

      <Button
        className="gap-2 text-yellow-base"
        onClick={() => navigate(PATHNAMES.SUBSCRIPTION)}
      >
        <span>{subscription}</span>
        <EditIcon />
      </Button>
    </div>
  );
};
