import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Logo } from "src/components/Logo";
import { PATHNAMES } from "src/constants/routes";
import { RegistrationForm } from "./RegistrationForm";

export const Registration: FC = () => {
  return (
    <>
      <section className="flex h-screen flex-col justify-between bg-registration-banner bg-contain bg-no-repeat lg:hidden">
        <div className="flex min-h-[200px] items-start justify-between px-4 py-3">
          <Logo />
          <Link
            to={PATHNAMES.HOME}
            className="mt-5 text-lg font-bold text-white-base"
          >
            &larr; Back
          </Link>
        </div>

        <div className="flex-1 rounded-t-3xl bg-[#FBFBFB] px-6 shadow-lg">
          <RegistrationForm />

          <p className="mt-0 text-center">
            Already have an ccount?
            <Link className="ml-1 text-yellow-base" to={PATHNAMES.SIGN_IN}>
              Log in
            </Link>
          </p>
        </div>
      </section>
      <section className="hidden h-full bg-registration-banner bg-cover bg-no-repeat lg:block">
        <div className="z-20 h-full w-1/2 rounded-r-40 bg-white-base px-20 py-8">
          <Logo />
          <RegistrationForm />

          <p className="mt-4 text-center">
            Already have an ccount?
            <Link className="ml-1 text-yellow-base" to={PATHNAMES.SIGN_IN}>
              Log in
            </Link>
          </p>
        </div>
      </section>
    </>
  );
};
