import React from "react";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { ReactComponent as ArrowRight } from "src/assets/icons/arrow-right.svg";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";

interface IServiceItemProps {
  image: string;
  title: string;
  subtitle: string;
  description: string;
  link: string;
}

const ServiceItem = ({
  image,
  title,
  subtitle,
  description,
  link,
}: IServiceItemProps) => {
  return (
    <div className="rounded-[28px] border border-gray-base">
      <div className="relative">
        <div className="absolute bottom-0 right-15 translate-y-1/2 rounded-full bg-white-base p-1">
          <Button variant={ButtonVariants.PRIMARY} className="p-3">
            <ArrowRight />
          </Button>
        </div>
        <img src={image} alt={title} />
      </div>

      <div className="flex flex-col gap-4 p-10 pt-4">
        <div className="flex flex-col gap-1">
          <h5 className="text-gray-strong text-2xl font-semibold leading-[33.60px]">
            Basic Cleaning
          </h5>
          <p className="text-gray-strong flex items-center gap-1 text-sm font-medium capitalize tracking-wider">
            <StarIcon />
            Essential Care for Your Home
          </p>
        </div>

        <p>
          <div className="text-black-third text-base font-normal leading-7">
            Ideal for regular upkeep, our Basic Cleaning service ensures that
            your home stays in top shape without the hassle. Enjoy more free
            time while we take care of the cleaning!
          </div>
        </p>
      </div>
    </div>
  );
};

export default ServiceItem;
