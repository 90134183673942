import React, { FC } from "react";
import { useNavigate } from "react-router";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import BlockTitle from "src/page-components/account/BlockTitle";
import RequestItem from "src/page-components/account/bookings/RequestItem";
import RatingPicker from "src/page-components/account/review/RatingPicker";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { ReviewsList } from "src/page-components/home/Reviews/ReviewsList";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { TextInput } from "src/components/FormField/TextInput";
import { Header } from "src/components/Header";
import { Rating } from "src/components/Rating";
import NumberedRating from "src/components/Rating/NumberedRating";
import { Sizes } from "src/@types/sizes";

const REVIEW_FORM_VALIDATION_SCHEMA = Yup.object().shape({
  rating: Yup.number().required("Rating is required"),
  cleaner: Yup.string().required("Cleaner is required"),
  comment: Yup.string().required("Comment is required"),
});

const REVIEW_INITIAL_VALUES = {
  rating: 0,
  cleaner: "Sarah Johnson",
  comment: "",
};

const CreateReview: FC = () => {
  const navigate = useNavigate();

  const formikProps = {
    initialValues: REVIEW_INITIAL_VALUES,
    validationSchema: REVIEW_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {},
  };

  const formik = useFormik(formikProps);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div className="flex w-full items-center justify-between px-2">
        <Title title="Review" subtitle="" />
        <p onClick={handleBackClick}>Back</p>
      </div>
      <FormikProvider value={formik}>
        <Form>
          <div className="flex flex-col gap-10 px-5 pt-[54px] lg:px-0">
            <BlockTitle title="Rate your Cleaner" />

            <div className="flex items-start justify-between">
              <RatingPicker
                rating={formik.values.rating}
                setRating={(value) => formik.setFieldValue("rating", value)}
              />

              <div className="flex flex-col gap-1 text-sm font-normal capitalize leading-snug tracking-tight text-gray-strong">
                <div>Basic Cleaning</div>
                <div>09-02-2024</div>
              </div>
            </div>

            <TextInput
              disabled
              className="!rounded-[10px]"
              label="Your Cleaner"
              placeholder="Write your review here"
              name="cleaner"
            />

            <TextInput
              className="min-h-[120px] !rounded-[10px]"
              isTextArea
              label="Comment"
              placeholder="Write your review here"
              name="comment"
            />

            <div className="self-end">
              <Button size={Sizes.S} variant={ButtonVariants.PRIMARY}>
                Save Review
              </Button>
            </div>
          </div>
        </Form>
      </FormikProvider>
      <div className="mt-5 block lg:hidden">
        <Footer />
      </div>
    </>
  );
};

export default CreateReview;
