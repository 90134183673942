import cn from "classnames";

interface IRadioButtonProps {
  options: { value: string; label: string }[];
  name: string;
  label?: string;
  selectedValue: { value: string; label: string };
  onChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    value: { value: string; label: string }
  ) => void;
  labelClassName?: string;
  className?: string;
}

const RadioButton = ({
  options,
  className,
  name,
  label,
  selectedValue,
  onChange,
  labelClassName,
  ...props
}: IRadioButtonProps) => {
  return (
    <div className={cn("flex flex-col gap-4", className)}>
      {label && (
        <label htmlFor={name} className={cn("", labelClassName)}>
          {label}
        </label>
      )}

      <div className="flex flex-col gap-3">
        {options.map((option) => (
          <label
            key={option.value}
            className="flex cursor-pointer items-center space-x-2"
          >
            <input
              {...props}
              type="radio"
              name={name}
              value={option.value}
              checked={selectedValue.value === option.value}
              onChange={(e) => onChange(e, option)}
              className="h-5 w-5 border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <span className="text-gray-800">{option.label}</span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioButton;
