import React, { FC } from "react";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import iconImg from "src/assets/images/home/our-services/icon.png";
import { SERVICES_ITEMS } from "./contants";

export const OurServicesList: FC = () => {
  return (
    <>
      <div className="mt-12.5">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={20}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          className="lg:hidden"
        >
          {SERVICES_ITEMS.map(({ id, title, description, url }) => (
            <SwiperSlide key={id}>
              <div className="flex flex-col gap-4 rounded-lg bg-blue-50 p-6 text-center shadow-md">
                <div className="mx-auto h-16 w-20">
                  <img src={iconImg} alt={title} />
                </div>

                <span className="text-xl font-semibold text-gray-800">
                  {title}
                </span>

                <p className="text-sm text-gray-600">{description}</p>

                <Link
                  className="text-sm font-bold text-orange-500 transition-all duration-200 hover:text-orange-400"
                  to={url}
                >
                  Learn More
                </Link>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* Версия без свайпера для больших экранов */}
        <div className="mt-12.5 hidden items-center gap-15 lg:flex">
          {SERVICES_ITEMS.map(({ id, title, description, url }) => (
            <div key={id} className="flex flex-col gap-4">
              <div className="h-16 w-20">
                <img src={iconImg} alt={title} />
              </div>

              <span className="text-2xl font-semibold text-black-ligth">
                {title}
              </span>

              <p>{description}</p>

              <Link
                className="text-sm font-bold text-orange-strong transition-all duration-200 hover:text-orange-base"
                to={url}
              >
                Learn More
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
