import React from "react";
import NotFoundImage from "src/assets/images/not-found/notFound.png";

const NotFoundCleaners = () => {
  return (
    <div className="py:0 container flex flex-col items-center justify-between lg:flex-row lg:py-16">
      <div className="flex flex-col gap-32">
        <div className="flex flex-col gap-7">
          <h2 className="test-[45px] leadeing-[45px] w-[90%] font-semibold uppercase tracking-[10px] !text-black-third lg:max-w-[636px] lg:text-[100px] lg:leading-[100px]">
            No cleaners found
          </h2>
          <p className="w-[90%] text-lg font-normal leading-[28.80px] text-black-third opacity-60 lg:w-[599px]">
            We currently don’t have cleaners available in your area. We’re
            working hard to expand our service and hope to reach you soon!
          </p>
        </div>
      </div>

      <div className="w-full">
        <img
          className="h-full w-full"
          src={NotFoundImage}
          alt="Not found cleaners"
        />
      </div>
    </div>
  );
};

export default NotFoundCleaners;
