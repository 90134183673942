import React, { FC, KeyboardEvent, useMemo } from "react";
import InputMask from "react-input-mask";
import ReactPhoneInput, {
  formatPhoneNumber,
  Value,
} from "react-phone-number-input/input";
import MaskedInput from "react-text-mask";
import cn from "classnames";
import { useField, useFormikContext } from "formik";
import { FormField } from "src/components/FormField";
import { TEXT_INPUT_STYLE_VARIANTS } from "src/components/FormField/constants";
import { FormFieldVariants } from "src/components/FormField/types";
import { IFormField } from "src/@types/form";

export interface IPhoneInputProps {
  id?: string;
  name?: string;
  label?: string;
  mask?: (string | RegExp)[];
  placeholder?: string;
  className?: string;
  fieldClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  variant?: FormFieldVariants;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string | number;
  isShownError?: boolean;
  error?: string;
  min?: number;
}

const PhoneInput: FC<IPhoneInputProps> = ({
  className,
  fieldClassName,
  labelClassName,
  id,
  name,
  label,
  mask,
  variant = FormFieldVariants.PRIMARY,
  placeholder,
  disabled,
  onChange,
  value,
  isShownError,
  error,
  min,
}) => {
  const fieldId = id || name;

  const combinedClassNames = cn(
    "w-full outline-none disabled:bg-[#D0D5DD33] disabled:cursor-default disabled:border-[#D0D5DD33]",
    TEXT_INPUT_STYLE_VARIANTS[variant],
    className
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  return (
    <FormField
      className={fieldClassName}
      labelClassName={labelClassName}
      variant={variant}
      label={label}
      labelFor={fieldId}
      isShownError={isShownError}
      error={error}
    >
      <InputMask
        name={name}
        placeholder={placeholder}
        className={combinedClassNames}
        mask={mask ? mask : "999 999 9999"}
        disabled={disabled}
        value={value}
        onChange={handleChange}
      />
    </FormField>
  );
};

export default PhoneInput;
