import React from "react";

const OfferItem = ({ title, rate }: { title: string; rate: string }) => {
  return (
    <div className="flex flex-col gap-[10px]">
      <div className="text-xl font-medium capitalize leading-relaxed text-gray-dark">
        {title}
      </div>
      <div className="text-black-third text-base font-semibold">{rate}</div>
    </div>
  );
};

const TimesItem = ({ times }: { times: string[] }) => {
  return (
    <div className="text-black-third text-base font-light leading-normal">
      <ul>
        {times.map((time) => (
          <li key={time}>{time}</li>
        ))}
      </ul>
    </div>
  );
};

const RateInformation = () => {
  return (
    <div className="">
      <div className="rounded-t-[24px] bg-gray-dark p-[42px]">
        <div className="text-[32px] font-semibold !text-white-base">
          Rate Information
        </div>
        <p className="text-base font-medium leading-normal !text-white-base">
          Direct payment to <span className="text-yellow-base">cleaners</span>
        </p>
      </div>

      <div className="overflow-x-auto rounded-b-[24px] border border-gray-dark">
        <table className="bg-white min-w-full rounded-lg">
          <tbody>
            <tr>
              <td className="border-r border-gray-dark p-[42px] pb-3">
                <OfferItem title="1 Cleaner" rate="$35/hr" />
              </td>
              <td className="p-[42px] pb-3">
                <TimesItem
                  times={[
                    "Basic clean - 2hr minimum",
                    "Deep clean – 5hr minimum",
                    "Move Out clean – 5hr minimum",
                  ]}
                />
              </td>
            </tr>
            <tr>
              <td className="border-r border-gray-dark px-[42px] py-3">
                <OfferItem title="Team Of 2 Cleaners" rate="$50/hr" />
              </td>
              <td className="px-[42px] py-3">
                <TimesItem
                  times={[
                    "Basic clean - 2hr minimum",
                    "Deep clean – 4hr minimum",
                    "Move Out clean – 4hr minimum",
                  ]}
                />
              </td>
            </tr>
            <tr>
              <td className="border-r border-gray-dark px-[42px] py-3">
                <OfferItem title="Team Of 3 Cleaners" rate="$70/hr" />
              </td>
              <td className="px-[42px] py-3">
                <TimesItem
                  times={[
                    "Basic clean - 2hr minimum",
                    "Deep clean – 4hr minimum",
                    "Move Out clean – 4hr minimum",
                  ]}
                />
              </td>
            </tr>
            <tr>
              <td className="border-r border-gray-dark p-[42px] pt-3">
                <OfferItem title="Team of 4 cleaners" rate="$100/hr" />
              </td>
              <td className="p-[42px] pt-3">
                <TimesItem
                  times={[
                    "Basic clean - 2hr minimum",
                    "Deep clean – 3.5hr minimum",
                    "Move Out clean – 3.5hr minimum",
                  ]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RateInformation;
