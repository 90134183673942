import React, { useState } from "react";
import useClientStore from "src/store/client-store";
import { TextInput } from "src/ui/TextInput";
import { ITeamMember } from "src/@types/users";
import BlockTitle from "../../BlockTitle";

const CleanerTeamEditForm = () => {
  const { user, updateUserField, isChangingPersonalData } = useClientStore();

  const handleAddMember = () => {
    updateUserField("team_members", [
      ...user.team_members,
      { name: "", experience_years: 0 },
    ]);
  };

  const handleDeleteMember = (index: number) => {
    const newTeamMembers = user.team_members.filter((_, i) => i !== index);

    updateUserField("team_members", newTeamMembers);
  };

  const handleInputChange = (
    index: number,
    field: string,
    value: string | number
  ) => {
    const updatedTeamMembers: ITeamMember[] = [...user.team_members];

    updatedTeamMembers[index][field] = value;

    updateUserField("team_members", updatedTeamMembers);
  };

  return (
    <div className="mt-10 flex flex-col gap-6">
      <BlockTitle title="My team" />

      <div className="flex flex-col gap-6">
        {user.team_members.map((member, index) => (
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between gap-2">
              <div className="text-lg font-normal capitalize leading-7 tracking-tight text-gray-strong">
                Member #{index + 1}
              </div>

              {isChangingPersonalData && (
                <button
                  type="button"
                  onClick={() => handleDeleteMember(index)}
                  className="text-red-500"
                >
                  Delete
                </button>
              )}
            </div>

            <div key={index} className="flex gap-2">
              <TextInput
                name={`name-${index}`}
                value={member.name}
                onChange={(e) =>
                  handleInputChange(index, "name", e.target.value)
                }
                placeholder="Name & Surname"
                isShownError={false}
                error={""}
                className="!rounded-10"
                disabled={!isChangingPersonalData}
              />

              <TextInput
                type="number"
                min={0}
                name={`experience_years-${index}`}
                value={member.experience_years}
                onChange={(e) =>
                  handleInputChange(
                    index,
                    "experience_years",
                    parseInt(e.target.value, 10)
                  )
                }
                placeholder="Experience"
                className="!rounded-10"
                disabled={!isChangingPersonalData}
              />
            </div>
          </div>
        ))}
      </div>

      {isChangingPersonalData && (
        <div className="flex items-center justify-center">
          <button
            type="button"
            onClick={handleAddMember}
            className="mt-4 text-blue-500"
          >
            + Add Member
          </button>
        </div>
      )}
    </div>
  );
};

export default CleanerTeamEditForm;
