import React, { FC } from "react";
import cn from "classnames";
import { FormField } from "src/components/FormField";
import { TEXT_INPUT_STYLE_VARIANTS } from "src/components/FormField/constants";
import { FormFieldVariants } from "src/components/FormField/types";

export interface ISelectInputProps {
  id?: string;
  name: string;
  label?: string;
  options: { value: string | number; label: string }[];
  placeholder?: string;
  className?: string;
  fieldClassName?: string;
  labelClassName?: string;
  disabled?: boolean;
  variant?: FormFieldVariants;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string | number;
  isShownError?: boolean;
  error?: string;
}

export const SelectInput: FC<ISelectInputProps> = ({
  className,
  fieldClassName,
  labelClassName,
  id,
  label,
  variant = FormFieldVariants.PRIMARY,
  options,
  placeholder,
  disabled,
  onChange,
  value,
  isShownError,
  error,
  ...props
}) => {
  const fieldId = id || props.name;

  const combinedClassNames = cn(
    "w-full outline-none disabled:bg-[#D0D5DD33] disabled:cursor-default disabled:border-[#D0D5DD33]",
    TEXT_INPUT_STYLE_VARIANTS[variant],
    className
  );

  return (
    <FormField
      className={fieldClassName}
      labelClassName={labelClassName}
      variant={variant}
      label={label}
      labelFor={fieldId}
      isShownError={isShownError}
      error={error}
    >
      <select
        id={fieldId}
        className={combinedClassNames}
        value={value}
        disabled={disabled}
        onChange={onChange}
        {...props}
      >
        <option value="" disabled>
          {placeholder || "Select an option"}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </FormField>
  );
};
