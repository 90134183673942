import React from "react";
import { ReactComponent as DeleteIcon } from "src/assets/icons/trash.svg";

interface IWeekdayItemProps {
  title: string;
  start_time: string;
  end_time: string;
  onDelete: () => void;
}

const WeekdayItem = ({
  title,
  start_time,
  end_time,
  onDelete,
}: IWeekdayItemProps) => {
  return (
    <div className="flex items-center justify-between gap-4">
      <div>
        <div>{title}:</div>
        <div>
          {start_time} - {end_time}
        </div>
      </div>

      <button className="text-red-600" onClick={onDelete}>
        <DeleteIcon />
      </button>
    </div>
  );
};

export default WeekdayItem;
