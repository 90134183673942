import { instance } from "../api-client";

interface IClientUpdateRequest {
  name?: string;
  phone_number?: string;
  state?: string;
  address?: string;
}

export interface ICleanerUpdateRequest {
  name: string;
  phone_number: string;
  zip_code: string;
  experience_years: number;
  bio: string;
  cleaning_type_ids: number[];
  team_members: { name: string; experience_years: number }[];
}

export interface ICreateCleanerSchedules {
  weekday: number;
  start_time: string;
  end_time: string;
}

export const clientServices = {
  async updateClient(clientData: IClientUpdateRequest) {
    const { data } = await instance.patch("api/clients/me", clientData);

    return data;
  },

  async getClientBookings() {
    const { data } = await instance.get("api/clients/me/cleaning-requests");

    return data;
  },

  async updateCleaner(cleanerData: ICleanerUpdateRequest) {
    const { data } = await instance.patch("api/cleaners/me", cleanerData);

    return data;
  },

  async getInsurance() {
    const { data } = await instance.get("api/cleaners/me/file/insurance");

    return data;
  },

  async updateInsurance(body: FormData) {
    const { data } = await instance.patch(
      "api/cleaners/me/file/insurance",
      body
    );

    return data;
  },

  async getLicense() {
    const { data } = await instance.get("api/cleaners/me/file/license");

    return data;
  },

  async updateLicense(body: FormData) {
    const { data } = await instance.patch("api/cleaners/me/file/license", body);

    return data;
  },

  async getClientsSubscriptions() {
    const { data } = await instance.get("api/subscriptions/clients");

    return data;
  },

  async getCleanersSubscriptions() {
    const { data } = await instance.get("api/subscriptions/cleaners");

    return data;
  },

  async getCleanerSchedules() {
    const { data } = await instance.get("/api/cleaners/me/schedules");

    return data;
  },

  async createCleanerSchedules(body: ICreateCleanerSchedules) {
    const { data } = await instance.post("/api/cleaners/me/schedules", body);

    return data;
  },

  async updateCleanerSchedules(weekday: number, body: ICreateCleanerSchedules) {
    const { data } = await instance.patch(
      `/api/cleaners/me/schedules/${weekday}`,
      body
    );

    return data;
  },

  async deleteCleanerSchedules(weekday: number) {
    const { data } = await instance.delete(
      `/api/cleaners/me/schedules/${weekday}`
    );

    return data;
  },

  async getReviewsToCleaner(cleaner_id: number) {
    const { data } = await instance.get(
      `/api/cleaners/${cleaner_id}/reviews/received`
    );

    return data;
  },
};

export default clientServices;
