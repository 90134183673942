import React, { FC, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import cn from "classnames";
import bookingServices from "src/services/booking-service";
import useBookCleaningStore from "src/store/book-cleaning-store";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import { Button } from "../Button";
import { ButtonVariants } from "../Button/types";
import { TEXT_INPUT_STYLE_VARIANTS } from "../FormField/constants";

interface Props {
  className?: string;
  onSearch?: () => void;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const Search: FC<Props> = ({ className, value, onChange, onSearch }) => {
  const { cleaning_type, team_size, date, zip_code, time } =
    useBookCleaningStore();

  const { refetch: refetchGetAvailableCleaners } = useQuery({
    queryKey: ["getAvailableCleaners"],
    queryFn: () =>
      bookingServices.getAvailableCleaners({
        cleaning_type_id: cleaning_type.id,
        team_size: team_size.value,
        date: `${date} ${time}`,
        zip_code,
      }),
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const handleSearchClick = () => {
    onSearch();
    refetchGetAvailableCleaners();
  };

  return (
    <div className={cn("group flex w-full flex-col sm:flex-row", className)}>
      <input
        className={cn(
          TEXT_INPUT_STYLE_VARIANTS.primary,
          "w-full lg:rounded-r-none"
        )}
        placeholder="Enter your postal code"
        value={value}
        onChange={onChange}
      />

      <Link to={PATHNAMES.AVAILABLE_CLEANERS}>
        <Button
          className="mt-2 w-full min-w-56 group-active:translate-y-0 sm:mt-0 sm:-translate-x-20 lg:min-w-35"
          variant={ButtonVariants.PRIMARY}
          size={Sizes.S}
          onClick={handleSearchClick}
        >
          Search
        </Button>
      </Link>
    </div>
  );
};
