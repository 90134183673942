import { instance } from "../api-client";

// interface IClientUpdateRequest {
//   name: string;
//   phone_number: string;
//   state: string;
//   address: string;
// }

interface IGetAvailableCleaners {
  zip_code: string;
  cleaning_type_id: number;
  date: string;
  team_size: number;
}

export interface ICleaningType {
  id: number;
  name: string;
  description: string;
}

interface IGetCleaningRatesQuery {
  team_size: number;
  cleaning_type_id: number;
}

export interface ICleaningRatesData {
  cleaning_type: ICleaningType;
  team_size: number;
  hourly_rate: number;
  minimum_hours: number;
}

export const bookingServices = {
  async getCleaningTypes() {
    const { data } = await instance.get("api/cleaning/types");

    return data;
  },

  async getCleaningRates({
    team_size,
    cleaning_type_id,
  }: IGetCleaningRatesQuery): Promise<ICleaningRatesData[]> {
    const { data } = await instance.get<ICleaningRatesData[]>(
      `api/cleaning/rates?team_size=${team_size}&cleaning_type_id=${cleaning_type_id}`
    );

    return data;
  },

  async getAvailableCleaners({
    zip_code,
    cleaning_type_id,
    date,
    team_size,
  }: IGetAvailableCleaners) {
    const { data } = await instance.get(
      `api/cleaners/available-cleaners?zip_code=${zip_code}&cleaning_type_id=${cleaning_type_id}&date=${date}&team_size=${team_size}`
    );

    return data;
  },
};

export default bookingServices;
