import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import useClientStore from "src/store/client-store";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Search } from "src/components/Search";
import { addQueryParams } from "src/utils/url";
import { PATHNAMES } from "src/constants/routes";
import { ReactComponent as StarIcon } from "src/assets/icons/star.svg";
import bannerImg from "src/assets/images/home/hero-section/banner.png";
import { Sizes } from "src/@types/sizes";
import { Users } from "src/@types/users";

export const HeroSection: FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const isAuthorized = useClientStore((state) => state.isAuthorized);
  const bookNowHref = isAuthorized
    ? PATHNAMES.BOOK_CLEANING
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLIENT });

  const cleanWithUsHref = isAuthorized
    ? PATHNAMES.ACCOUNT
    : addQueryParams(PATHNAMES.SIGN_UP, { type: Users.CLEANER });

  return (
    <section>
      <div className="container mt-10 flex flex-col items-center gap-3 pt-3.5 lg:mt-0 lg:flex-row">
        <div className="flex-1">
          <div className="flex flex-col gap-3.5">
            <div className="flex items-center gap-5">
              <StarIcon />
              <span className="text-center uppercase text-yellow-base lg:text-left">
                sparkle the Cleaning Services
              </span>
            </div>

            <h1 className="text-[50px] lg:text-[64px]">
              Cleaning Services For All Your Needs
            </h1>

            <p className="max-w-122.5">
              Looking for a professional cleaning service? Choose from a wide
              range of vetted cleaners, available for one-time or recurring
              cleanings. Book online in just a few clicks.
            </p>
          </div>

          <div className="mt-5 flex flex-col gap-4 lg:flex-row">
            <Link to={bookNowHref}>
              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                className="w-full"
              >
                Book a Cleaner Now
              </Button>
            </Link>

            <Link to={cleanWithUsHref}>
              <Button
                size={Sizes.S}
                variant={ButtonVariants.OPACITY_PRIMARY}
                className="w-full"
              >
                Become A Cleaner With Us
              </Button>
            </Link>
          </div>

          <div className="mt-10 hidden lg:block">
            <span className="text-2xl font-semibold text-black-ligth">
              Find your cleaner now!
            </span>

            <Search
              className="mt-2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>

        <div className="w-full max-w-130">
          <img src={bannerImg} alt="Sparkle the Cleaning Services" />
          <div className="static mt-10 block lg:hidden">
            <span className="text-2xl font-semibold text-black-ligth">
              Find your cleaner now!
            </span>

            <Search
              className="mt-2"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
