import React, { memo } from "react";
import cn from "classnames";
import { ReactComponent as SearchIcon } from "src/assets/icons/search.svg";

interface ISearchFieldProps {
  icon?: React.ReactNode;
  clearIcon?: boolean;
  label?: string | JSX.Element;
  hint?: string | JSX.Element;
  placeholder?: string;
  type?: string;
  id?: string;
  name?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null | boolean;
  value?: string | number;
  disabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  border?: boolean;
  primary?: boolean;
  isOptional?: boolean;
}

const SearchField = ({
  label,
  icon,
  clearIcon,
  hint,
  placeholder,
  type,
  id,
  name,
  className,
  onChange,
  error,
  value,
  disabled,
  onKeyDown,
  onFocus,
  onClear,
  onBlur,
  border,
  primary,
  isOptional,
}: ISearchFieldProps): JSX.Element => {
  const identifier = id || name || type;
  const isError = !!error;

  const handleClear = () => {
    if (onClear) {
      onClear();
    }

    onChange &&
      onChange({
        target: { value: "", name: identifier },
      } as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className="w-full">
      <div
        className={cn("relative", {
          "mt-1": label,
        })}
      >
        <input
          type={type}
          value={value}
          name={name}
          id={identifier}
          onChange={onChange}
          onKeyDown={onKeyDown}
          onFocus={onFocus}
          onBlur={onBlur}
          className={cn(
            "block w-full border-b border-[#D8D8D8] bg-transparent py-[11px] pr-12 text-sm font-normal leading-6 transition-all placeholder:text-[#707070]",
            className
          )}
          placeholder={placeholder}
          aria-describedby={`${identifier}-optional`}
          disabled={disabled}
        />
        {
          <button
            onClick={handleClear}
            className="absolute right-3 top-1/2 -translate-y-1/2"
          >
            {<SearchIcon />}
          </button>
        }
      </div>
    </div>
  );
};

export default memo(SearchField);
