import Cookies from "js-cookie";
import { instance } from "src/services/api-client";
import { isTokenExpired } from "src/services/interceptors";
import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { NotificationService } from "src/helpers/notifications";
import { AUTH_REFRESH_TOKEN } from "src/constants/cookiesKeys";
import { SubscriptionType } from "src/@types/subscription";
import { ICleaner, IClient, Users } from "src/@types/users";
import avatar from "./temp/avatar_temp.png";

interface IClientStore {
  user: IClient | ICleaner | null;

  type: Users.CLIENT | Users.CLEANER;
  isAuthorized: boolean;
  isChangingPersonalData: boolean;
  subscription: string;

  updateUserField: (fieldName: string, value: any) => void;
  updateField: (fieldName: string, value: any) => void;
  getClient: () => void;
  clearClient: () => void;
}

const useClientStore = create(
  devtools<IClientStore>((set) => ({
    user: {
      avatar: avatar,
      id: 0,
      name: "",
      phone_number: "",
      email: "",

      team_members: [],
      license: null,
      insurance: null,
      profile_status: "",
    },

    isAuthorized: false,
    type: Users.CLIENT,

    subscription: SubscriptionType.BASIC,

    isChangingPersonalData: false,

    updateUserField: (fieldName, value) =>
      set((state) => ({
        ...state,
        user: {
          ...state.user,
          [fieldName]: value,
        },
      })),

    updateField: (fieldName, value) => {
      set((state) => ({
        ...state,
        [fieldName]: value,
      }));
    },

    getClient: async () => {
      try {
        const refreshToken = Cookies.get(AUTH_REFRESH_TOKEN);
        const isRefreshTokenExpired = isTokenExpired(refreshToken);

        if (isRefreshTokenExpired) return null;

        const { data } = await instance.get("/api/auth/me");

        set((state) => ({
          type: data.type,
          user: { ...state.user, ...data.user },
          isAuthorized: Boolean(data),
        }));

        return data;
      } catch ({ response }) {
        const errorText = response?.data?.error;

        set({ isAuthorized: false });

        NotificationService.error(errorText);
      }
    },
    clearClient: () => {
      set({
        isAuthorized: false,
        user: {
          avatar: avatar,
        } as IClient,
        subscription: SubscriptionType.BASIC,
      });
    },
  }))
);

export default useClientStore;
