import EmailVerification from "src/page-components/sing-in/EmailVerification";
import { ModalNotificationType } from "../../constants";
import NotificationItem from "./NotificationItem";

interface INotificationModalManagerProps {
  notificationType: ModalNotificationType | null;
  isOpen: boolean;
  closeModal: () => void;
}

const NotificationModalManager = ({
  isOpen,
  notificationType,
}: INotificationModalManagerProps) => {
  if (!isOpen) return null;

  switch (notificationType) {
    case ModalNotificationType.REGISTER_SUCCESS:
      return <EmailVerification />;

    case ModalNotificationType.EMAIL_VERIFICATION:
      return <EmailVerification />;

    case ModalNotificationType.EMAIL_EXISTS:
      return (
        <NotificationItem
          title="Email already exists"
          message="Please try to log in or use another email."
        />
      );

    case ModalNotificationType.INVALID_CREDENTIALS:
      return (
        <NotificationItem
          title="Invalid email or password"
          message="Please try to log in again"
        />
      );

    default:
      return (
        <NotificationItem
          title="Something went wrong"
          message="Please try again later"
        />
      );
  }
};

export default NotificationModalManager;
