import React, { FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Logo } from "src/components/Logo";
import { RenderFormFields } from "src/components/RenderFormFields";
import { PATHNAMES } from "src/constants/routes";
import { Sizes } from "src/@types/sizes";
import {
  AUTH_FORM_FIELDS,
  AUTH_FORM_VALIDATION_SCHEMA,
  AUTH_INITIAL_VALUES,
} from "./constants";
import { IFormikValues } from "./types";

const ForgetPasswordForm = () => {
  const navigate = useNavigate();
  // const login = useAuthStore((state) => state.login);
  // const getClient = useClientStore((state) => state.getClient);

  const formikProps: FormikConfig<IFormikValues> = {
    initialValues: AUTH_INITIAL_VALUES,
    validationSchema: AUTH_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {
      // login(values, () => {
      //   getClient();
      //   navigate(PATHNAMES.HOME);
      // });
    },
  };

  const formik = useFormik(formikProps);

  return (
    <div>
      <FormikProvider value={formik}>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-3">
            <h2 className="text-center text-[24px] font-semibold text-black-third lg:text-start lg:text-[32px]">
              Forget Your Password?
            </h2>
            <p className="text-center text-base font-normal leading-normal text-[#666666] lg:text-start">
              No worries, we’ve got you covered!
              <br />
              Enter the email address associated with your account, and we’ll
              send you instructions to reset your password.
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <Form className="flex flex-col gap-6">
              <div className="flex flex-col gap-6">
                <RenderFormFields fields={AUTH_FORM_FIELDS} />
              </div>

              <Button
                className="w-full"
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                type="submit"
              >
                Send Reset Instructions
              </Button>
            </Form>

            <p className="text-center">
              Back to
              <Link className="ml-1 text-yellow-base" to={PATHNAMES.SIGN_IN}>
                Login
              </Link>
            </p>
          </div>
        </div>
      </FormikProvider>
    </div>
  );
};

export default ForgetPasswordForm;
