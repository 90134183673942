import React from "react";
import cn from "classnames";
import { CloseButton } from "../CloseButton";

interface IModalPrimaryTemplateProps {
  children: React.ReactNode;
  onClose: () => void;
  className?: string;
}

const ModalPrimaryTemplate = ({
  children,
  className,
  onClose,
}: IModalPrimaryTemplateProps) => {
  return (
    <div
      className={cn("relative rounded-[30px] bg-white-base p-16", className)}
    >
      <div className="absolute right-5 top-5">
        <CloseButton onClose={onClose} />
      </div>
      <div className="h-full max-h-[calc(100vh-280px)] max-w-295 overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default ModalPrimaryTemplate;
