import React, { FC, useEffect, useState } from "react";
import useAuthStore from "src/store/auth-store";
import useClientStore from "src/store/client-store";
import useSubscriptionStore from "src/store/subscription-store";
import { Users } from "src/@types/users";
import { SectionHeader } from "../SectionHeader";
import { AvailableServices } from "./AvailableServices";
import { CurrentSubscription } from "./CurrentSubscription";
import { ServicePlan } from "./ServicePlan";
import { ToggleButton } from "./ToggleButton";

export const SubscriptionSection: FC = () => {
  const [activeUser, setActiveUser] = useState(Users.CLIENT);

  const getActiveUser = useAuthStore((state) => state.getActiveUser);
  const activeUserInfo = useAuthStore((state) => state.activeUserInfo);

  const fetchUserSubscription = useSubscriptionStore(
    (state) => state.fetchUserSubscription
  );

  const activeUserSubscription = useSubscriptionStore(
    (state) => state.activeUserSubscription
  );

  useEffect(() => {
    getActiveUser();
    fetchUserSubscription();
  }, [getActiveUser, fetchUserSubscription]);

  useEffect(() => {
    if (activeUserInfo) {
      setActiveUser(activeUserInfo.type);
    }
  }, [activeUserInfo]);

  return (
    <section>
      <div className="container py-24">
        <CurrentSubscription userSubscriptions={activeUserSubscription} />
        <div className="flex flex-col items-center gap-6">
          <SectionHeader
            title="Choose the Right Plan for You"
            subtitle="Pricing Table"
            description="3 weeks of cleaning for only $19.99. Book as many times as you like. Cancel anytime."
            isCenter
          />

          {/* <ToggleButton activeUser={activeUser} setActiveUser={setActiveUser} /> */}
        </div>

        <ServicePlan activeUser={activeUser} />
        <AvailableServices />
      </div>
    </section>
  );
};
