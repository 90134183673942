import React, { FC } from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import clientServices from "src/services/client-service";
import useClientStore from "src/store/client-store";
import { AccountProfileHeader } from "src/page-components/account/personal-data/AccountProfileHeader";
import { CleanerEditForm } from "src/page-components/account/personal-data/CleanerEditForm";
import CleanerTeamEditForm from "src/page-components/account/personal-data/CleanerTeamEditForm";
import { EditForm } from "src/page-components/account/personal-data/EditForm";
import { ResetPassword } from "src/page-components/account/personal-data/ResetPassword";
import { Title } from "src/page-components/book-cleaning/CleaningStepForm/Steps/Title";
import { Button } from "src/components/Button";
import { ButtonVariants } from "src/components/Button/types";
import { Footer } from "src/components/Footer";
import { Header } from "src/components/Header";
import { isCleanerType } from "src/utils/getUserType";
import { Sizes } from "src/@types/sizes";

const PersonalData: FC = () => {
  const { user, type, updateField, isChangingPersonalData } = useClientStore(
    (state) => state
  );

  const navigate = useNavigate();

  const handleIsChangingPersonalData = (value: boolean) => {
    updateField("isChangingPersonalData", value);
  };

  const handleUpdateCleanerInfo = async () => {
    const cleaningTypeIds = user.cleaning_types.map((type) => type.id);

    const newCleanerData = {
      name: user.name,
      phone_number: user.phone_number.replace(/\s+/g, ""),
      zip_code: user.zip_code,
      experience_years: user.experience_years ? user.experience_years : 0,
      bio: user.bio,
      cleaning_type_ids: cleaningTypeIds ?? [],
      team_members: user.team_members,
    };

    const updateCleanerPromise = clientServices.updateCleaner(newCleanerData);

    const InsuranceFormData = new FormData();
    InsuranceFormData.append("file", user.insurance);

    const LicenseFormData = new FormData();
    LicenseFormData.append("file", user.license);

    const updateInsurancePromise =
      user.insurance && typeof user.insurance !== "string"
        ? clientServices.updateInsurance(InsuranceFormData)
        : Promise.resolve();
    const updateLicensePromise =
      user.license && typeof user.license !== "string"
        ? clientServices.updateLicense(LicenseFormData)
        : Promise.resolve();

    await Promise.all([
      updateCleanerPromise,
      updateInsurancePromise,
      updateLicensePromise,
    ]);
  };

  const handleUpdateClientInfo = async () => {
    const newClientData = {
      name: user.name,
      phone_number: user.phone_number.replace(/\s+/g, ""),
      state: user.state,
      address: user.address,
    };

    await clientServices.updateClient(newClientData);
  };

  const hanleSaveChanges = async () => {
    try {
      if (isCleanerType(type)) {
        handleUpdateCleanerInfo();
      } else {
        handleUpdateClientInfo();
      }

      handleIsChangingPersonalData(false);
      toast.success("Your data has been updated successfully");
    } catch (error) {
      console.log(error);
    }
  };

  console.log("User: ", user);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="block lg:hidden">
        <Header />
      </div>
      <div>
        <div className="flex w-full justify-between p-2 lg:hidden">
          <Title title="Personal Data" subtitle="" />

          <p onClick={handleBackClick}>Back</p>
        </div>
        <AccountProfileHeader />

        {isCleanerType(type) ? <CleanerEditForm /> : <EditForm />}

        {/* {isChangingPersonalData && <ResetPassword />} */}

        {isCleanerType(type) &&
          ((user.team_members && user.team_members.length > 0) ||
            isChangingPersonalData) && <CleanerTeamEditForm />}

        {/* <div className="mt-10">
          {isChangingPersonalData ? (
            <div className="flex justify-between">
              <Button
                onClick={() => handleIsChangingPersonalData(false)}
                size={Sizes.S}
                variant={ButtonVariants.CANCEL}
                type="button"
              >
                Cancel
              </Button>

              <Button
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
                type="submit"
                onClick={hanleSaveChanges}
              >
                Save Changes
              </Button>
            </div>
          ) : (
            <div className="flex justify-end">
              <Button
                onClick={() => handleIsChangingPersonalData(true)}
                size={Sizes.S}
                variant={ButtonVariants.PRIMARY}
              >
                Change information
              </Button>
            </div>
          )}
        </div> */}
      </div>
      <div className="block lg:hidden">
        <Footer />
      </div>
    </>
  );
};

export default PersonalData;
