import React, { FC } from "react";
import cn from "classnames";
import { Form, FormikConfig, FormikProvider, useFormik } from "formik";
import useBookCleaningStore from "src/store/book-cleaning-store";
import * as Yup from "yup";
import { Checkbox } from "src/components/FormField/Checkbox";
import RadioButton from "src/components/FormField/RadioButton";
import { TextInput } from "src/components/FormField/TextInput";
import { StepControlsProps } from "../../types";
import { Title } from "../Title";
import {
  FILLING_FORM_VALIDATION_SCHEMA,
  FILLING_INITIAL_VALUES,
} from "./constants";
import { IFormikFillingValues } from "./types";

const FIELD_CLASSNAME = "!rounded-[10px] border-gray-ligther";

export const InformationFillingForm: FC<StepControlsProps> = ({
  title,
  subtitle,
}) => {
  const {
    house_size,
    rooms,
    floors,
    updateField,
    bed_linen_change,
    has_kids,
    has_pets,
    bathrooms,
    additional_notes,
  } = useBookCleaningStore();

  const formikProps: FormikConfig<IFormikFillingValues> = {
    initialValues: FILLING_INITIAL_VALUES,
    validationSchema: FILLING_FORM_VALIDATION_SCHEMA,
    onSubmit: (values) => {},
  };

  const handleInputChange = (
    fieldName: string,
    value: string | boolean | { value: string; label: string }
  ) => {
    console.log("fieldName", fieldName);
    console.log("value", value);
    updateField(fieldName, value);
  };

  const formik = useFormik(formikProps);

  return (
    <div className="flex flex-col gap-10">
      <Title title={title} subtitle={subtitle} />

      <FormikProvider value={formik}>
        <Form className="flex flex-col gap-6">
          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <TextInput
              type="number"
              className={FIELD_CLASSNAME}
              placeholder="Enter the size of the house"
              label="House Size"
              name="house_size"
              value={house_size}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <TextInput
              type="number"
              className={FIELD_CLASSNAME}
              placeholder="Enter the number of floors"
              label="Number of floors"
              name="floors"
              value={floors}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <TextInput
              type="number"
              className={FIELD_CLASSNAME}
              placeholder="Enter the room count"
              label="Room Count"
              name="rooms"
              value={rooms}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
            <TextInput
              type="number"
              className={FIELD_CLASSNAME}
              placeholder="Enter the bethroom count"
              label="Bathroom Count"
              name="bathrooms"
              value={bathrooms}
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            />
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
            <RadioButton
              className="flex-1"
              label="Bed Linen Change"
              options={[
                {
                  value: "yes",
                  label: "Yes",
                },
                {
                  value: "no",
                  label: "No",
                },
              ]}
              name="bed_linen_change"
              selectedValue={bed_linen_change}
              onChange={(e, option) => {
                formik.setFieldValue("bed_linen_change", option.value);
                handleInputChange(e.target.name, option);
              }}
            />

            <div className="flex flex-1 flex-col gap-4">
              <div>Additional information</div>

              <div className="flex flex-col gap-3">
                <Checkbox
                  checked={has_kids}
                  onChangeValue={(e) =>
                    handleInputChange(e.target.name, e.target.checked)
                  }
                  name="has_kids"
                  label="Kids"
                />
                <Checkbox
                  checked={has_pets}
                  onChangeValue={(e) =>
                    handleInputChange(e.target.name, e.target.checked)
                  }
                  name="has_pets"
                  label="Pets"
                />
              </div>
            </div>
          </div>

          <TextInput
            className={cn("min-h-[115px]", FIELD_CLASSNAME)}
            placeholder="Message"
            isTextArea
            label="Additional comments"
            name="additional_notes"
            value={additional_notes}
            onChange={(e) => handleInputChange(e.target.name, e.target.value)}
          />
        </Form>
      </FormikProvider>
    </div>
  );
};
